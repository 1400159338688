import {
  Box,
  useColorModeValue,
  Flex,
  Button,
  useDisclosure,
  useBreakpointValue,
} from "@chakra-ui/react";
import Sidebar from "components/sidebar/Sidebar";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import routes from "routes";
import AdminNavbarLinks from "components/navbar/NavbarLinksAdmin";
import { useTranslation } from "react-i18next";
import CreateDevice from "views/devices/components/CreateDevice";
import CreateOperator from "views/operators/components/CreateOperator";
import { SnackbarProvider } from "notistack";
import CreateGroup from "views/permissions/components/CreateGroup";
import SliderPopup from "components/slider/SliderPopup";
import { Navigate } from "react-router-dom";
import { useMobileApp } from "contexts/stores";
import useWindowSize from "utils/useWindowSize";

export default function Dashboard(props) {
  const { ...rest } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const location = useLocation();
  const { t } = useTranslation();

  const addDeviceDisclosure = useDisclosure();
  const addOperatorDisclosure = useDisclosure();
  const addGroupDisclosure = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isMobileApp } = useMobileApp.getState();

  const handleSidebarToggle = (isOpen) => setIsSidebarOpen(isOpen);

  const getRoutes = (routes) =>
    routes.map((prop, key) => (
      <Route path={prop.path} element={prop.element} key={key} />
    ));

  const renderModalOrDrawer = () => {
    switch (location.pathname) {
      case "/devices":
        return (
          <>
            <SliderPopup
              isOpen={addDeviceDisclosure.isOpen}
              onClose={addDeviceDisclosure.onClose}
            >
              <CreateDevice onClose={addDeviceDisclosure.onClose} />
            </SliderPopup>
          </>
        );

      case "/operators":
        return (
          <SliderPopup
            isOpen={addOperatorDisclosure.isOpen}
            onClose={addOperatorDisclosure.onClose}
          >
            <CreateOperator onClose={addOperatorDisclosure.onClose} />
          </SliderPopup>
        );

      case "/permissions":
        return (
          <>
            <SliderPopup
              isOpen={addOperatorDisclosure.isOpen}
              onClose={addOperatorDisclosure.onClose}
            >
              <CreateOperator onClose={addOperatorDisclosure.onClose} />
            </SliderPopup>
            <SliderPopup
              isOpen={addGroupDisclosure.isOpen}
              onClose={addGroupDisclosure.onClose}
            >
              <CreateGroup onClose={addGroupDisclosure.onClose} />
            </SliderPopup>
          </>
        );

      default:
        return null;
    }
  };
  const { height } = useWindowSize();

  return (
    <Flex>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: isMobile ? "bottom" : "top",
          horizontal: "center",
        }}
        style={{ zIndex: 9999 }}
      >
        <SidebarContext.Provider
          value={{ toggleSidebar: false, setToggleSidebar: () => {} }}
        >
          <Sidebar
            routes={routes}
            {...rest}
            onToggle={handleSidebarToggle}
            isOpen={isSidebarOpen}
          />
          <Box
            ml={{ base: "0px", md: isSidebarOpen ? "250px" : "75px" }}
            flex="1"
            overflow={{ base: "none", md: "auto" }}
            position="relative"
            minHeight="100vh"
            transition="margin-left 0.3s"
            mb={isMobile ? (height <= 800 ? "11vh" : "7vh") : "0px"}
          >
            <Box
              id="top-box"
              bg={useColorModeValue("white", "gray.800")}
              boxShadow="md"
              p="4"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              position="sticky"
              top="0"
              zIndex="100"
            >
              {!isMobileApp && location.pathname === "/devices" && (
                <Button
                  bgColor="pcr.200"
                  border="1px"
                  rounded="md"
                  onClick={addDeviceDisclosure.onOpen}
                  _hover={{ bgColor: "pcr.100" }}
                >
                  {t("create_device")}
                </Button>
              )}
              {!isMobileApp && location.pathname === "/operators" && (
                <Button
                  bgColor="pcr.200"
                  border="1px"
                  rounded="md"
                  onClick={addOperatorDisclosure.onOpen}
                  _hover={{ bgColor: "pcr.100" }}
                >
                  {t("create_operator")}
                </Button>
              )}
              {!isMobileApp && location.pathname === "/permissions" && (
                <>
                  <Button
                    bgColor="pcr.200"
                    border="1px"
                    rounded="md"
                    onClick={addOperatorDisclosure.onOpen}
                    _hover={{ bgColor: "pcr.100" }}
                    mr={"10px"}
                  >
                    {t("create_operator")}
                  </Button>
                  <Button
                    bgColor="pcr.200"
                    border="1px"
                    rounded="md"
                    onClick={addGroupDisclosure.onOpen}
                    _hover={{ bgColor: "pcr.100" }}
                  >
                    {t("create_group")}
                  </Button>
                </>
              )}
              <Box ml="auto">
                <AdminNavbarLinks
                  onOpen={props.onOpen}
                  logoText={props.logoText}
                  secondary={props.secondary}
                  fixed={props.fixed}
                  scrolled={false}
                />
              </Box>
            </Box>
            <Box p="4" bgColor={"pcr.100"} minH={"89.9%"}>
              <Box
                mx="auto"
                p={{ base: "5px", md: "30px" }}
                pe={{ base: "5px", md: "30px" }}
              >
                <Routes>
                  {getRoutes(routes)}
                  {/* <Route path="*" element={<NotFound />} /> */}
                  <Route
                    path="/"
                    element={
                      <Navigate
                        to={isMobileApp || isMobile ? "/events" : "/dashboard"}
                        replace
                      />
                    }
                  />
                </Routes>
              </Box>
            </Box>
            {renderModalOrDrawer()}
          </Box>
        </SidebarContext.Provider>
      </SnackbarProvider>
    </Flex>
  );
}
