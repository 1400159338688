/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useEventsStore } from "contexts/stores";

// Chakra imports
import { Box, useBreakpointValue } from "@chakra-ui/react";
// Assets

// Custom components

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/generic/LoadingSpinner";
import FiltersTable from "components/filtersTable/FiltersTable";
import EventManage from "./components/EventHistory/EventManage";
import { columnsData } from "./variables/columnsData";
import { useToggle } from "components/externalComponents/ToggleContext";
import MeasuresTable from "views/measures";

const EventsPage = ({ applyFilter, isInDrawer = false }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { t } = useTranslation();
  const [refreshEventTable, setRefreshEventTable] = useState(false);

  const generateEventDescription = (event) => {
    return event?.kDescription !== event?.kName
      ? t("event_description1", {
          kName: event?.kName,
          kDescription: event?.kDescription,
          rootNames: event?.rootNames,
          rootTypes: event?.rootTypes,
        })
      : t("event_description2", {
          kName: event?.kName,
          rootNames: event?.rootNames,
          rootTypes: event?.rootTypes,
        });
  };

  const disableColumnFilter = ["description"];

  const statusOptions = [
    {
      key: "status",
      values: [
        { value: 0, label: t(`events_list.status_list.0`) },
        { value: 1, label: t("events_list.status_list.1") },
        { value: 2, label: t("events_list.status_list.2") },
        { value: 3, label: t("events_list.status_list.3") },
        { value: 4, label: t("events_list.status_list.4") },
      ],
    },
  ];

  const columnsList = [
    {
      Header: "raw_data_link",
      accessor: "buttonPanel",
      page: "events_list",
      sliderPopupComponent: (handleClose, row) => {
        return (
          <MeasuresTable
            applyFilter={[
              {
                column: "eventId",
                value: row?.original?.id,
              },
            ]}
            isInDrawer={true}
          />
        );
      },
    },
  ];
  const mobileColumnsList = [
    {
      Header: "events_list.manage_event",
      accessor: "buttonPanel",
      page: "events_list",
      sliderPopupComponent: (handleClose, row) => {
        return (
          <EventManage
            onClose={handleClose}
            eventID={row?.original?.id}
            refreshEventTable={setRefreshEventTable}
          />
        );
      },
    },
  ];

  const rowClick = {
    page: "events_list",
    render: (handleClose, row) => (
      <EventManage
        onClose={handleClose}
        eventID={row?.original?.id}
        refreshEventTable={setRefreshEventTable}
      />
    ),
  };

  const { isUpdating, setIsInDrawer, isInDrawerStore, fetchEventsPage } =
    useEventsStore((state) => ({
      isUpdating: state.isUpdating,
      setIsInDrawer: state.setIsInDrawer,
      isInDrawerStore: state.isInDrawer,
      fetchEventsPage: state.fetchEventsPage,
    }));

  const renderEvents = (e) => {
    return {
      id: e.id,
      time: e.time,
      deviceType: e.rootTypes.split(" > ")[0],
      deviceRoots: {
        main: e.rootNames,
        tooltip: e.rootTypes,
      },
      deviceName: e.rootNames.split(" > ")[e.rootNames.split(" > ").length - 1],
      description: generateEventDescription(e),
      location: e.location,
      status: e.status,
      variable: e.kName,
      variableDescription: e.kDescription,
      variableLevel: e.level,
      counter: e.rawDataCount,
    };
  };

  const fetchPageData = async (
    pageIndex,
    itemsPerPage,
    globalFilter,
    sortBy,
    filters
  ) => {
    try {
      const result = await fetchEventsPage(
        pageIndex,
        itemsPerPage,
        globalFilter,
        sortBy,
        filters
      );
      return {
        data: result.data.map((d) => renderEvents(d)),
        totalItems: result.totalItems,
      };
    } catch (error) {
      console.error("Error fetching page data:", error);
      return { data: [], totalItems: 0 };
    }
  };

  const { toggleStates } = useToggle(); // Accedi ai toggle

  useEffect(() => {
    //if I'm in the drawer I also update the devices if I detect a new fetchEvents (not the one inside this useEffect)
    if (!isInDrawer) {
      setIsInDrawer(isInDrawer);
    }
    if (isInDrawer && isInDrawerStore) {
      setIsInDrawer(false);
    }
    if (isInDrawer) setIsInDrawer(isInDrawer);
  }, []);

  useEffect(() => {
    if (refreshEventTable) setRefreshEventTable(false);
  }, [refreshEventTable]);

  const toggleObject = typeof toggleStates === "object" ? toggleStates : {};

  const pagination = {
    fetchPage: fetchPageData,
  };

  return isUpdating ? (
    <LoadingSpinner />
  ) : (
    <Box bgColor="white" padding="15px" shadow={"lg"}>
      {isMobile ? (
        <FiltersTable
          columnsList={mobileColumnsList}
          columnsVariables={columnsData}
          navigateToPage={isInDrawer ? "/events" : ""}
          rowClick={rowClick}
          pageType={"events_list"}
          statusOptions={statusOptions}
          hideColumnsOnLoad={{
            id: false,
            time: true,
            deviceRoots: true,
            location: false,
            status: false,
            description: false,
            variable: true,
            variableDescription: false,
            variableLevel: false,
            counter: false,
          }}
          applyFilter={applyFilter ?? ""}
          isInDrawer={isInDrawer}
          isVisible={!isInDrawer ?? true}
          isMobileTable={isMobile}
          pagination={pagination}
          paginated={true}
          refreshButton={true}
          disableColumnFilter={disableColumnFilter}
          refreshPage={refreshEventTable}
        />
      ) : (
        <FiltersTable
          columnsList={columnsList}
          columnsVariables={columnsData}
          navigateToPage={isInDrawer ? "/events" : ""}
          rowClick={rowClick}
          pageType={"events_list"}
          statusOptions={statusOptions}
          hideColumnsOnLoad={{
            id: false,
            time: true,
            deviceRoots: true,
            deviceId: true,
            deviceType: false,
            location: true,
            status: true,
            description: true,
            variable: true,
            variableDescription: false,
            variableLevel: true,
            counter: false,
          }}
          applyFilter={applyFilter ?? ""}
          isInDrawer={isInDrawer}
          isVisible={!isInDrawer ?? true}
          pagination={pagination}
          paginated={true}
          refreshButton={true}
          disableColumnFilter={disableColumnFilter}
          refreshPage={refreshEventTable}
        />
      )}
    </Box>
  );
};

export default EventsPage;
