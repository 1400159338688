import React from "react";
import { Box } from "@chakra-ui/react";
import LoadingSpinner from "components/generic/LoadingSpinner";
import { useTranslation } from "react-i18next";
import FiltersTable from "components/filtersTable/FiltersTable";

const ThresholdTable = ({ applyFilter, isInDrawer = false, data }) => {
  const { t } = useTranslation();

  const columnsVariables = [
    {
      Header: "crossing",
      accessor: "crossing",
      type: "text",
    },
    {
      Header: "firstCrossingDown",
      accessor: "firstCrossingDown",
      type: "date",
    },
    {
      Header: "firstCrossingUp",
      accessor: "firstCrossingUp",
      type: "date",
    },
    {
      Header: "lastCrossingDown",
      accessor: "lastCrossingDown",
      type: "date",
    },
    {
      Header: "lastCrossingUp",
      accessor: "lastCrossingUp",
      type: "date",
    },
    {
      Header: "statisticData",
      accessor: "statisticData",
      type: "text",
    },
    {
      Header: "statisticDataId",
      accessor: "statisticDataId",
      type: "text",
    },
    {
      Header: "thresholdValue",
      accessor: "thresholdValue",
      type: "text",
    },
    {
      Header: "thresholdId",
      accessor: "thresholdId",
      type: "text",
    },
    {
      Header: "timeOver",
      accessor: "timeOver",
      type: "text",
    },
    {
      Header: "timeUnder",
      accessor: "timeUnder",
      type: "text",
    },
  ];

  return data.length === 0 ? (
    <LoadingSpinner />
  ) : (
    <Box bgColor="white" padding="15px" shadow="lg">
      <FiltersTable
        columnsVariables={columnsVariables}
        pageType={"threshold_data_list"}
        tableData={data.map(({ key, ...rest }) => {
          return {
            statisticId: rest?.statisticId,
            thresholdId: rest?.thresholdId,
            thresholdValue: rest?.thresholdValue,
            ...rest,
          };
        })}
        hideColumnsOnLoad={{
          crossing: true,
          firstCrossingDown: true,
          firstCrossingUp: true,
          key: false,
          lastCrossingDown: true,
          lastCrossingUp: true,
          statisticId: false,
          thresholdValue: true,
          thresholdId: false,
          timeOver: true,
          timeUnder: true,
        }}
        applyFilter={applyFilter ?? ""}
        isInDrawer={isInDrawer}
        isVisible={!isInDrawer ?? true}
        navigateToPage={""}
        tableHeight={"80vh"}
      />
    </Box>
  );
};

export default ThresholdTable;
