import React from "react";

import { Icon } from "@chakra-ui/react";
import DvrIcon from "@mui/icons-material/Dvr";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";

// Admin Imports
import DevicesPage from "views/devices";
import EventsPage from "views/events";
import OperatorsPage from "views/operators";
import { ToggleProvider } from "components/externalComponents/ToggleContext";

const mobileRoutes = [
  {
    name: "sidebar.events",
    path: "events",
    icon: (
      <Icon
        as={ListAltOutlinedIcon}
        width="15px"
        height="15px"
        color="inherit"
      />
    ),
    element: (
      <ToggleProvider>
        <EventsPage />
      </ToggleProvider>
    ),
  },
  {
    name: "sidebar.devices",
    path: "devices",
    icon: <Icon as={DvrIcon} width="15px" height="15px" color="inherit" />,
    element: <DevicesPage />,
  },
  {
    name: "sidebar.operators",
    path: "operators",
    icon: (
      <Icon
        as={PeopleAltOutlinedIcon}
        width="15px"
        height="15px"
        color="inherit"
      />
    ),
    element: <OperatorsPage />,
    secondary: true,
  },
];

export default mobileRoutes;
