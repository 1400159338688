import { useVariableStore } from "contexts/stores";

// Chakra imports
import { Box, Flex, Text, Button, Heading } from "@chakra-ui/react";
// Assets

// Custom components

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/generic/LoadingSpinner";
import FiltersTable from "components/filtersTable/FiltersTable";
import { useToggle } from "components/externalComponents/ToggleContext";
import API from "api/API";
import { enqueueSnackbar } from "notistack";
import { checkTranslation } from "utils/StringConverter";
import ConfirmAction from "components/generic/ConfirmButton";
import { ErrorType } from "utils/Enum";

const customTextStyle = { fontWeight: "bold" };

const VariableDifferences = ({ variableData, refreshSAVariableTable }) => {
  const { isUpdating, variableSA, fetchVariableSA } = useVariableStore();
  const { t } = useTranslation();
  const bgColor = "pcr.100";
  const hoverColor = "pcr.200";
  const { variableSAHistory, setVariableSAHistory } =
    useVariableStore.getState();

  const [refreshSAVariableHistory, setRefreshSAVariableHistory] =
    useState(false);

  useEffect(() => {
    API.getSAVariableHistoryByVariableId(variableData?.id).then((result) => {
      if (result.isSuccess) {
        setVariableSAHistory(result.data);
      } else {
        console.error("failed fetch data");
      }
    });
  }, [variableData, refreshSAVariableHistory, setRefreshSAVariableHistory]);

  useEffect(() => {
    if (refreshSAVariableHistory) {
      setRefreshSAVariableHistory(false);
    }
  }, [refreshSAVariableHistory]);

  const columnsListSA = [
    {
      Header: "reset",
      accessor: "button",
      page: "variableSA_differences_list",
      onClick: (row) => {
        const mostRecent = variableSAHistory
          .filter(
            (item) =>
              item.variableId === variableData.id &&
              item.deviceId === row.original.deviceID
          )
          .reduce((latest, current) => {
            return !latest || new Date(current.time) > new Date(latest.time)
              ? current
              : latest;
          }, null);
        const dataFormatted = {
          ...row?.original,
          isSAError: row?.original?.isGAError,
          lastUpdate: mostRecent ? mostRecent?.time : new Date(),
        };
        handleReset(dataFormatted);
      },
    },
  ];

  const handleResetAll = (e) => {
    API.resetAllSAVariable(variableData.id, variableSAHistory).then(
      (result) => {
        if (result.isSuccess) {
          setRefreshSAVariableHistory(true);
          refreshSAVariableTable(true);
          enqueueSnackbar(t("variable_form.success"), {
            variant: "success",
          });
        } else {
          console.error("failed get data");
          enqueueSnackbar(
            checkTranslation(
              t(`variable_errors.${result.errorMsg || result.data}`),
              "variable_errors.${result.errorMsg || result.data}",
              t("variable_form.error")
            ),
            {
              variant: "error",
            }
          );
        }
      }
    );
  };

  const handleReset = (data) => {
    API.resetSAVariable(data).then((result) => {
      if (result.isSuccess) {
        setRefreshSAVariableHistory(true);
        refreshSAVariableTable(true);
        enqueueSnackbar(t("variable_form.success"), {
          variant: "success",
        });
      } else {
        console.error("failed get data");
        enqueueSnackbar(
          checkTranslation(
            t(`variable_errors.${result.errorMsg || result.data}`),
            "variable_errors.${result.errorMsg || result.data}",
            t("variable_form.error")
          ),
          {
            variant: "error",
          }
        );
      }
    });
  };

  const fetchPageData = async (
    pageIndex,
    itemsPerPage,
    globalFilter,
    sortBy,
    filters
  ) => {
    try {
      const result = await fetchVariableSA(
        pageIndex,
        itemsPerPage,
        globalFilter,
        sortBy,
        filters,
        true
      );
      return {
        data:
          result.data.length > 0
            ? result.data.map(
                ({ key, deviceNavigation, isSAErrorHistory, ...rest }) => {
                  return {
                    ...rest,
                    isSAError:
                      rest?.isSAError === ErrorType.RESET
                        ? rest?.isGAError
                        : rest?.isSAError === ErrorType.OKAY
                        ? false
                        : rest?.isSAError === undefined ||
                          rest?.isSAError === null
                        ? rest?.isGAError
                        : true,
                  };
                }
              )
            : [],
        totalItems: result.totalItems,
      };
    } catch (error) {
      console.error("Error fetching page data:", error);
      return { data: [], totalItems: 0 };
    }
  };

  const pagination = {
    fetchPage: fetchPageData,
  };

  const { toggleStates, handleToggleChange } = useToggle(); // Accedi ai toggle

  return (!variableSA || variableSA.length === 0) && isUpdating ? (
    <LoadingSpinner />
  ) : (
    <Box bgColor="white" height={"100%"} width={"100%"}>
      {" "}
      <Heading size="md" pb={"20px"} textAlign={"center"}>
        {" "}
        {t("variableSA_differences_list.title")}
      </Heading>
      <Box borderRadius="md" mt="1rem" minH={"80vh"}>
        <ConfirmAction
          title={t("variableSA_differences_list.reset_variables")}
          onConfirm={handleResetAll}
          triggerButton={
            <Button
              bgColor={"pcr.200"}
              _hover={{ bgColor: hoverColor }}
              ml={"5px"}
              mb={5}
            >
              {t("reset_all")}
            </Button>
          }
        />
        {variableSA !== undefined && variableSA !== null ? (
          <FiltersTable
            columnsList={columnsListSA}
            tableData={variableSA}
            pageType={"variableSA_differences_list"}
            toggleStates={toggleStates}
            onToggleChange={handleToggleChange}
            hideColumnsOnLoad={{
              deviceId: false,
              variableId: false,
              dName: true,
              vName: false,
              vDescription: false,
              vType: true,
              root: true,
              type: true,
              rootType: true,
              isGAError: false,
              isSAError: true,
            }}
            pagination={pagination}
            paginated={true}
            refreshButton={true}
            refreshPage={refreshSAVariableHistory}
            refreshFunction={() => {
              API.getSAVariableHistoryByVariableId(variableData?.id).then(
                (result) => {
                  if (result.isSuccess) {
                    setVariableSAHistory(result.data);
                  } else {
                    console.error("failed fetch data");
                  }
                }
              );
            }}
            isInDrawer={true}
            applyFilter={
              variableData
                ? [
                    {
                      column: "variableId",
                      value: variableData?.id,
                    },
                  ]
                : ""
            }
            isVisible={false}
            areButtonsInvisible={true}
          />
        ) : (
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            w={"100%"}
            minH={"100vh"}
          >
            <Flex alignItems={"center"}>
              <Text pl="0.5rem" fontSize="4xl" as={"b"}>
                {t("empty_data")}
              </Text>
            </Flex>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default VariableDifferences;
