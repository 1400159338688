import { useVariableStore } from "contexts/stores";

// Chakra imports
import {
  Box,
  Flex,
  Text,
  useBreakpointValue,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
// Assets

// Custom components

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/generic/LoadingSpinner";
import FiltersTable from "components/filtersTable/FiltersTable";
import { useToggle } from "components/externalComponents/ToggleContext";
import { formatDate } from "components/filtersTable/TableContent";
import API from "api/API";
import { enqueueSnackbar } from "notistack";
import UpdateVariableLayout from "./components/UpdateSeverityLayout";
import { useKeycloak } from "contexts/stores";
import { useOperatorsStore } from "contexts/stores";
import LogViewer from "./components/LogsViewer";
import ConfigurationForm from "./components/GeneralSettingsForm";
import { ErrorType } from "utils/Enum";

const customTextStyle = { fontWeight: "bold" };

export default function SettingsPage() {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { variable, isUpdating, fetchVariable, variableSA, fetchVariableSA } =
    useVariableStore();
  const { t } = useTranslation();
  const bgColor = "pcr.100";
  const hoverColor = "pcr.200";

  const { keycloak } = useKeycloak();
  const { operators, fetchOperators } = useOperatorsStore();
  const [authLogs, setAuthLogs] = useState(false);
  const [refreshSAVariableTable, setRefreshSAVariableTable] = useState(false);

  useEffect(() => {
    if (refreshSAVariableTable) setRefreshSAVariableTable(false);
  }, [refreshSAVariableTable]);

  const columnsVariablesSA = [
    {
      Header: "differences",
      accessor: "differences",
      type: "bool",
      translationKey: "GA_SA_differences",
    },
    {
      Header: "isSAError",
      accessor: "isSAError",
      type: "bool",
    },
    {
      Header: "isGAError",
      accessor: "isGAError",
      type: "bool",
    },
  ];

  const columnsVariables = [
    {
      Header: "id",
      accessor: "id",
      type: "id",
    },
    {
      Header: "isError",
      accessor: "isError",
      type: "bool",
    },
  ];

  const columnsList = [
    {
      Header: "details",
      accessor: "buttonPanel",
      page: "variable_list",
      sliderPopupComponent: (handleClose, row) => (
        <UpdateVariableLayout
          onClose={handleClose}
          variableId={row?.original?.id}
          variableData={row?.original}
          isSADifferences={true}
          refreshSAVariableTable={setRefreshSAVariableTable}
        />
      ),
    },
  ];
  const columnsListSA = [
    {
      Header: "details",
      accessor: "buttonPanel",
      page: "variableSA_list",
      sliderPopupComponent: (handleClose, row) => (
        <UpdateVariableLayout
          onClose={handleClose}
          variableId={row?.original?.variableId}
          variableData={row?.original}
          deviceId={row?.original?.deviceId}
          isSA={true}
          refreshSAVariableTable={setRefreshSAVariableTable}
        />
      ),
    },
  ];
  const handleExport = () => {
    function convertToCSV(data) {
      const headers = Object.keys(data[0]).map((name) => {
        return t(`settings.login_history_headers.${name}`);
      });

      const csvRows = [headers.join(",")];

      data.forEach((event) => {
        const row = Object.keys(data[0]).map((field) => {
          if (field === "time") {
            return formatDate(event[field]);
          }
          return event[field];
        });
        csvRows.push(row.join(","));
      });

      return csvRows.join("\n");
    }

    function exportToCSV(csvData, pageType) {
      const currentDate = new Date();

      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const hour = String(currentDate.getHours()).padStart(2, "0");
      const minute = String(currentDate.getMinutes()).padStart(2, "0");

      const formattedDate = `${year}_${month}_${day} ${hour}.${minute}`;

      var filename =
        formattedDate + "_" + t(`export_name.${pageType}`) + ".csv";

      const blob = new Blob([csvData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", filename);
      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);
    }

    API.getLoginHistory().then((result) => {
      if (result.isSuccess) {
        var data = convertToCSV(result.data);
        exportToCSV(data, "settings_login");
      } else {
        enqueueSnackbar(t("settings.login_api_error"), {
          variant: "error",
        });
      }
    });
  };

  const fetchPageData = async (
    pageIndex,
    itemsPerPage,
    globalFilter,
    sortBy,
    filters
  ) => {
    try {
      const result = await fetchVariableSA(
        pageIndex,
        itemsPerPage,
        globalFilter,
        sortBy,
        filters
      );
      return {
        data: result.data.map(
          ({ key, deviceNavigation, isSAErrorHistory, ...rest }) => {
            const isSAError = rest?.isSAError;
            const result = {
              ...rest,
              isSAError:
                rest?.isSAError === ErrorType.RESET
                  ? rest?.isGAError
                  : rest?.isSAError === ErrorType.OKAY
                  ? false
                  : rest?.isSAError === undefined || rest?.isSAError === null
                  ? rest?.isGAError
                  : true,
            };
            return {
              ...result,
              differences:
                isSAError !== undefined && isSAError !== null
                  ? isSAError !== ErrorType.RESET
                  : false,
            };
          }
        ),
        totalItems: result.totalItems,
      };
    } catch (error) {
      console.error("Error fetching page data:", error);
      return { data: [], totalItems: 0 };
    }
  };

  const pagination = {
    fetchPage: fetchPageData,
  };

  useEffect(() => {
    fetchVariable();
    fetchOperators();
  }, [fetchVariable]);

  useEffect(() => {
    if (operators)
      if (operators.find((o) => o.id === keycloak.subject && o?.logs)) {
        setAuthLogs(true);
      }
  }, [operators]);

  const { toggleStates, handleToggleChange } = useToggle(); // Accedi ai toggle

  return (!variable || variable.length === 0) && isUpdating ? (
    <LoadingSpinner />
  ) : (
    <Box bgColor="white" padding="15px" shadow={"lg"}>
      <Button
        onClick={handleExport}
        bgColor={"pcr.200"}
        _hover={{ bgColor: hoverColor }}
        ml={"5px"}
        mb={5}
      >
        {t("settings.export_login_history")}{" "}
      </Button>

      <Accordion allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton
              // onClick={handleToggle}
              bgColor={bgColor}
              _hover={{ bgColor: hoverColor }}
            >
              <Box as="span" flex="1" textAlign="center" ml={"5px"}>
                <Text fontSize={"xl"} as={"b"}>
                  {t("variable_list.show_variableTableGA")}{" "}
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {" "}
            <Box
              border="2px solid"
              borderColor="gray.300"
              borderRadius="md"
              p="1rem"
              mt="1rem"
            >
              {variable !== undefined && variable !== null ? (
                <FiltersTable
                  columnsList={columnsList}
                  columnsVariables={columnsVariables}
                  tableData={variable}
                  pageType={"variable_list"}
                  toggleStates={toggleStates}
                  onToggleChange={handleToggleChange} // Passiamo la funzione per gestire i toggle
                  hideColumnsOnLoad={{
                    id: false,
                    level: true,
                    kName: true,
                    kDescription: true,
                    type: true,
                    deviceType: true,
                    rootType: true,
                    isError: true,
                  }}
                />
              ) : (
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  w={"100%"}
                  minH={"100vh"}
                >
                  <Flex alignItems={"center"}>
                    <Text pl="0.5rem" fontSize="4xl" as={"b"}>
                      {t("empty_data")}
                    </Text>
                  </Flex>
                </Flex>
              )}
            </Box>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton bgColor={bgColor} _hover={{ bgColor: hoverColor }}>
              <Box as="span" flex="1" textAlign="center" ml={"5px"}>
                <Text fontSize={"xl"} as={"b"}>
                  {t("variable_list.show_variableTableSA")}{" "}
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {" "}
            <Box
              border="2px solid"
              borderColor="gray.300"
              borderRadius="md"
              p="1rem"
              mt="1rem"
            >
              {variableSA !== undefined && variableSA !== null ? (
                <FiltersTable
                  columnsList={columnsListSA}
                  columnsVariables={columnsVariablesSA}
                  tableData={variableSA}
                  pageType={"variableSA_list"}
                  toggleStates={toggleStates}
                  onToggleChange={handleToggleChange}
                  hideColumnsOnLoad={{
                    deviceId: false,
                    variableId: false,
                    dName: true,
                    vName: false,
                    vDescription: true,
                    vType: true,
                    root: true,
                    type: true,
                    rootType: true,
                    isGAError: false,
                    isSAError: true,
                  }}
                  pagination={pagination}
                  paginated={true}
                  refreshButton={true}
                  refreshPage={refreshSAVariableTable}
                  customTextStyle={(cell) => {
                    return cell?.column?.id === "isSAError" &&
                      cell?.row?.original?.differences
                      ? customTextStyle
                      : {};
                  }}
                />
              ) : (
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  w={"100%"}
                  minH={"100vh"}
                >
                  <Flex alignItems={"center"}>
                    <Text pl="0.5rem" fontSize="4xl" as={"b"}>
                      {t("empty_data")}
                    </Text>
                  </Flex>
                </Flex>
              )}
            </Box>
          </AccordionPanel>
        </AccordionItem>
        {authLogs && (
          <AccordionItem>
            <h2>
              <AccordionButton
                bgColor={bgColor}
                _hover={{ bgColor: hoverColor }}
              >
                <Box as="span" flex="1" textAlign="center" ml={"5px"}>
                  <Text fontSize={"xl"} as={"b"}>
                    {t("settings.view_logs")}{" "}
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <LogViewer />
            </AccordionPanel>
          </AccordionItem>
        )}
        <AccordionItem>
          <h2>
            <AccordionButton bgColor={bgColor} _hover={{ bgColor: hoverColor }}>
              <Box as="span" flex="1" textAlign="center" ml={"5px"}>
                <Text fontSize={"xl"} as={"b"}>
                  {t("settings_form.configuration")}
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <ConfigurationForm />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}
