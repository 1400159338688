import { format, parseISO } from "date-fns";
import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useTranslation } from "react-i18next";

const colorStatus = [
  "#4CAF50", // Green
  "#ffd00d", // Yellow
  "#F44336", // Red
];

const colorIcons = [
  <AddOutlinedIcon />,
  <EditOutlinedIcon />,
  <DeleteOutlineOutlinedIcon />,
];

const TimelineComponent = ({ logs }) => {
  const { t } = useTranslation();

  const groupLogsByTime = (logs) => {
    const groupedLogs = {};

    logs.forEach((log) => {
      const timeKey = format(new Date(log.time), "yyyy-MM-dd HH:mm:ss");

      if (!groupedLogs[timeKey]) {
        groupedLogs[timeKey] = [];
      }

      groupedLogs[timeKey].push(log);
      groupedLogs[timeKey].user = {
        username: log?.usernameUser,
        firstName: log?.firstNameUser,
        lastName: log?.lastNameUser,
      };
    });

    return Object.entries(groupedLogs).map(([timeKey, grouped]) => ({
      timeKey,
      logs: grouped,
    }));
  };

  const convertIfBoolean = (value) => {
    if (value === true || value === "true") return t("true");
    if (value === false || value === "false") return t("false");
    return value; // o lancia un errore, a seconda dei casi d'uso
  };

  const groupedLogs = groupLogsByTime(logs);
  return (
    <VerticalTimeline lineColor="#EEEEEE">
      {groupedLogs.map(({ timeKey, logs }) => {
        return (
          <VerticalTimelineElement
            key={timeKey}
            date={
              <div
                className="timeline-date"
                style={{
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  display: "flex",
                  textAlign: "center",
                }}
              >
                {format(parseISO(timeKey + "Z"), "yyyy-MM-dd")}
                <br />
                {format(parseISO(timeKey + "Z"), "HH:mm:ss")}
              </div>
            }
            iconStyle={{ background: colorStatus[logs[0].type], color: "#000" }}
            icon={colorIcons[logs[0].type]}
          >
            <h3
              className="vertical-timeline-element-title"
              style={{ fontWeight: "bold", fontSize: "20px" }}
            >
              {t(`device_history_type.${logs[0].type}`)}
            </h3>
            {logs.map((log) => {
              if (log.property.toLowerCase() === "id") {
                return;
              }
              const field = t(
                `operators_list.${
                  log.property.charAt(0).toLowerCase() + log.property.slice(1)
                }`
              );
              if (field === t(`operators_list.availability`)) return;
              return (
                <p key={log.id} style={{ marginTop: "5px" }}>
                  {field}: {convertIfBoolean(log.newValue)}
                </p>
              );
            })}
            <h4
              className="vertical-timeline-element-subtitle"
              style={{ fontWeight: "bold" }}
            >
              {t("user")}: {logs?.user?.firstName} {logs?.user?.lastName}
            </h4>
          </VerticalTimelineElement>
        );
      })}
    </VerticalTimeline>
  );
};

export default TimelineComponent;
