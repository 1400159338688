import React from "react";
import { Box, Flex, IconButton, Text } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useWindowSize from "utils/useWindowSize";

const BottomAppBar = ({ routes }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { height } = useWindowSize();

  return (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      bg="gray.800"
      zIndex="10"
      shadow="lg"
      height={height <= 800 ? "10vh" : "8vh"}
      pb={1}
    >
      <Flex justify="space-around" align="center" pt={1}>
        {routes.map((route) => {
          const isSelected = location.pathname === "/" + route.path;

          return (
            <Flex
              key={route.name}
              direction="column"
              align="center"
              onClick={() => navigate(route.path)}
              cursor="pointer"
            >
              <IconButton
              padding={"1px"}
                aria-label={route.name}
                icon={route.icon}
                bg={isSelected ? "pcr.200" : "gray.800"}
                size="md"
                color={isSelected ? "black" : "yellow.400"}
                _hover={{ bg: isSelected ? "pcr.200" : "gray.800" }}
                p={0}
                _focus={{ bg: isSelected ? "pcr.200" : "gray.800" }}
                _active={{ bg: isSelected ? "pcr.200" : "gray.800" }}
              />
              <Text fontSize="xs" color="pcr.200">
                {t(`${route.name}`)}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
};

export default BottomAppBar;
