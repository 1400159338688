import React from "react";
import { Box, Heading, SimpleGrid, useBreakpointValue } from "@chakra-ui/react";
import VariableHistory from "../VariableHistory/VariableHistory";
import SeverityForm from "./SeverityForm";
import VariableDifferences from "./VariableDifferences";

const UpdateVariableLayout = ({
  onClose,
  variableData,
  variableId,
  deviceId,
  isSA,
  isSADifferences,
  refreshSAVariableTable,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box height="calc(100vh - 110px)" overflowY={isMobile ? "auto" : "hidden"}>
      <Heading size="md" pb={"20px"} pt={"10px"} textAlign={"center"}>
        {isSA
          ? variableData?.vDescription === variableData?.vName
            ? `${variableData?.vName} - ${variableData?.vType}`
            : `${variableData?.vName} - ${variableData?.vDescription} - ${variableData?.vType}`
          : variableData?.kDescription === variableData?.kName
          ? `${variableData?.kDescription} - ${variableData?.type}`
          : `${variableData?.kName} - ${variableData?.kDescription} - ${variableData?.type}`}
      </Heading>
      <SimpleGrid
        columns={isMobile ? 1 : 2}
        spacing={4}
        height={isMobile ? "auto" : "100%"}
      >
        <Box
          p={4}
          borderRadius="md"
          boxShadow="md"
          overflowY="hidden"
          height={isMobile ? "auto" : "100%"}
        >
          <SeverityForm
            variableData={variableData}
            onClose={onClose}
            isSA={isSA}
            refreshSAVariableTable={refreshSAVariableTable}
          />
          {isSADifferences && (
            <Box
              p={4}
              borderRadius="md"
              boxShadow="md"
              overflowY={"auto"}
              height={isMobile ? "auto" : "75%"}
            >
              <VariableHistory
                onClose={onClose}
                variableId={variableId}
                deviceId={deviceId}
                isSA={isSA}
              />
            </Box>
          )}
        </Box>

        {!isSADifferences ? (
          <Box
            p={4}
            pr={0}
            borderRadius="md"
            boxShadow="md"
            overflowY={"auto"}
            height={isMobile ? "auto" : "100%"}
          >
            <VariableHistory
              onClose={onClose}
              variableId={variableId}
              deviceId={deviceId}
              isSA={isSA}
            />
          </Box>
        ) : (
          <Box
            p={4}
            pr={0}
            borderRadius="md"
            boxShadow="md"
            overflowY={"hidden"}
            height={isMobile ? "auto" : "100%"}
          >
            <VariableDifferences
              variableData={variableData}
              refreshSAVariableTable={refreshSAVariableTable}
            />
          </Box>
        )}
      </SimpleGrid>
    </Box>
  );
};

export default UpdateVariableLayout;
