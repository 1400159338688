import { config } from "config";
import groups from "./groups.json";
import notifications from "./notifications.json";
import { get, post, put, del } from "./APILib";

const host = config.endpoints.api;
const v = config.endpoints.api_version;

const dataStore = {
  groups: [...groups],
  notifications: [...notifications],
};

const API = {
  statistic: {
    getPaginated: async (
      offset,
      limit,
      globalFilter = "",
      sortBy = [],
      filters = []
    ) => {
      const sortField = sortBy.length > 0 ? sortBy[0].id : "statisticId";
      const reversed = sortBy.length > 0 ? sortBy[0].desc : true;

      const params = new URLSearchParams({
        from: offset,
        number: limit,
        sortBy: sortField,
        reversed: reversed,
      });

      if (globalFilter) {
        params.append("filter", globalFilter);
      }

      filters.forEach((filter, index) => {
        params.append(`filters[${index}].Column`, filter.column);
        if (
          filter.column === "timestamp" &&
          filter.value &&
          typeof filter.value === "object"
        ) {
          if (filter.value.fromDate !== undefined) {
            params.append(
              `filters[${index}].DateRange.fromDate`,
              filter.value.fromDate
            );
          }
          if (filter.value.toDate !== undefined) {
            params.append(
              `filters[${index}].DateRange.toDate`,
              filter.value.toDate
            );
          }
        } else {
          params.append(`filters[${index}].Value`, filter.value);
        }
      });

      const url = `${host}/${v}/Statistic/paginated?${params.toString()}`;
      return get(url);
    },
  },

  // GET methods
  getAllLogs: async (date) => get(`${host}/${v}/Log/${date ?? `\"\"`}`),

  /* Devices */
  getAllDevices: async () => get(`${host}/${v}/Device`),
  getRootDevices: async () => get(`${host}/${v}/Device/root`),
  getDeviceById: async (id) => get(`${host}/${v}/Device/${id}`),
  getDeviceHistoryByDeviceId: async (id) =>
    get(`${host}/${v}/Device/history/${id}`),
  getDevicesPage: async (startIndex, nItems) =>
    get(`${host}/${v}/Device/page`, {
      startIndex: startIndex,
      nItems: nItems,
    }),
  getDeviceStatusHistoryByDeviceId: async (deviceId) =>
    get(`${host}/${v}/Device/status-history/${deviceId}`),
  getAllRemovedDevices: async () => get(`${host}/${v}/Device/deleted`),
  getAllTemplateDevices: async () => get(`${host}/${v}/Device/templateDevice`),

  /* Events */
  getAllEvents: async () => get(`${host}/${v}/Event`),
  getEventById: async (id) => get(`${host}/${v}/Event/${id}`),
  getEventsPaginated: async (
    offset,
    limit,
    globalFilter = "",
    sortBy = [],
    filters = []
  ) => {
    const sortField = sortBy.length > 0 ? sortBy[0].id : "time";
    const reversed = sortBy.length > 0 ? sortBy[0].desc : true;

    const params = new URLSearchParams({
      from: offset,
      number: limit,
      sortBy: sortField,
      reversed: reversed,
    });

    if (globalFilter) {
      params.append("filter", globalFilter);
    }

    filters.forEach((filter, index) => {
      params.append(`filters[${index}].Column`, filter.column);
      if (
        filter.column === "time" &&
        filter.value &&
        typeof filter.value === "object"
      ) {
        if (filter.value.fromDate !== undefined) {
          params.append(
            `filters[${index}].DateRange.fromDate`,
            filter.value.fromDate
          );
        }
        if (filter.value.toDate !== undefined) {
          params.append(
            `filters[${index}].DateRange.toDate`,
            filter.value.toDate
          );
        }
      } else {
        params.append(`filters[${index}].Value`, filter.value);
      }
    });

    const url = `${host}/${v}/Event/paginated?${params.toString()}`;
    return get(url);
  },

  /* Keycloack */
  getAllOperators: async () => get(`${host}/${v}/Keycloak/users`),
  getOperatorHistoryByUsername: async (username) =>
    get(`${host}/${v}/Keycloak/user/${username}/history`),
  getAllGroups: async () => get(`${host}/${v}/Keycloak/groups`),
  getGroupOperators: async (group) =>
    get(`${host}/${v}/Keycloak/groups/${group.name}/users`),
  getUserGroups: async () => get(`${host}/${v}/Keycloak/user/groups`),
  getGroupPermissions: async () => {
    return;
  },
  getUserPermission: async () => {
    return;
  },
  getLoginHistory: async () => get(`${host}/${v}/Keycloak/events`),

  /* Measures */
  getAllMeasures: async () => get(`${host}/${v}/RawData`),
  getAllMeasuresComplete: async () => get(`${host}/${v}/RawData/complete`),
  getMeasuresCount: async () => get(`${host}/${v}/RawData/count`),
  getFilteredMeasuresCount: async () =>
    get(`${host}/${v}/RawData/filtered-count`),
  getMeasuresPaginated: async (
    offset,
    limit,
    globalFilter = "",
    sortBy = [],
    filters = []
  ) => {
    const sortField = sortBy.length > 0 ? sortBy[0].id : "timestamp";
    const reversed = sortBy.length > 0 ? sortBy[0].desc : true;

    const params = new URLSearchParams({
      from: offset,
      number: limit,
      sortBy: sortField,
      reversed: reversed,
    });

    if (globalFilter) {
      params.append("filter", globalFilter);
    }

    filters.forEach((filter, index) => {
      params.append(`filters[${index}].Column`, filter.column);
      if (
        filter.column === "timestamp" &&
        filter.value &&
        typeof filter.value === "object"
      ) {
        if (filter.value.fromDate !== undefined) {
          params.append(
            `filters[${index}].DateRange.fromDate`,
            filter.value.fromDate
          );
        }
        if (filter.value.toDate !== undefined) {
          params.append(
            `filters[${index}].DateRange.toDate`,
            filter.value.toDate
          );
        }
      } else {
        params.append(`filters[${index}].Value`, filter.value);
      }
    });

    const url = `${host}/${v}/RawData/complete/paginated?${params.toString()}`;
    return get(url);
  },

  /* Variable */
  getAllVariable: async () => get(`${host}/${v}/Variable`),
  getVariableHistoryByVariableId: async (variableId) =>
    get(`${host}/${v}/Variable/history/${variableId}`),
  getSAVariableHistoryByVariableIdAndDeviceId: async (variableId, deviceId) =>
    get(
      `${host}/${v}/Variable/sa/history?variableId=${variableId}&deviceId=${deviceId}`
    ),
  getSAVariableHistoryByVariableId: async (variableId) =>
    get(`${host}/${v}/Variable/sa/history?variableId=${variableId}`),
  getSAVariablePaginated: async (
    offset,
    limit,
    globalFilter = "",
    sortBy = [],
    filters = [],
    differences = false
  ) => {
    const sortField = sortBy.length > 0 ? sortBy[0].id : "deviceId";
    const reversed = sortBy.length > 0 ? sortBy[0].desc : true;

    const params = new URLSearchParams({
      from: offset,
      number: limit,
      sortBy: sortField,
      reversed: reversed,
      differences: differences,
    });

    if (globalFilter) {
      params.append("filter", globalFilter);
    }

    filters.forEach((filter, index) => {
      params.append(`filters[${index}].Column`, filter.column);
      params.append(`filters[${index}].Value`, filter.value);
    });

    const url = `${host}/${v}/Variable/sa/paginated?${params.toString()}`;
    return get(url);
  },

  /* Tag */
  getAllTags: async () => get(`${host}/${v}/Tag`),
  getAllDeviceTags: async (id) => get(`${host}/${v}/Tag/all-device-tags/${id}`),

  /* EventHistory */
  getEventHistoryById: async (id) =>
    get(`${host}/${v}/EventHistory/event/${id}`),

  /* PCR */
  getServerInfo: async () => get(`${host}/${v}/PCR/info`),

  /* Notification */
  getNotifications: async () => {
    return { isSuccess: true, data: dataStore.notifications };
  }, // get(`${host}/${v}/Notification`),
  getNotificationsPaginated: async (
    offset,
    limit,
    globalFilter = "",
    sortBy = [],
    filters = []
  ) => {
    const sortField = sortBy.length > 0 ? sortBy[0].id : "time";
    const reversed = sortBy.length > 0 ? sortBy[0].desc : true;

    const params = new URLSearchParams({
      from: offset,
      number: limit,
      sortBy: sortField,
      reversed: reversed,
    });

    if (globalFilter) {
      params.append("filter", globalFilter);
    }

    filters.forEach((filter, index) => {
      params.append(`filters[${index}].Column`, filter.column);
      if (
        filter.column === "time" &&
        filter.value &&
        typeof filter.value === "object"
      ) {
        if (filter.value.fromDate !== undefined) {
          params.append(
            `filters[${index}].DateRange.fromDate`,
            filter.value.fromDate
          );
        }
        if (filter.value.toDate !== undefined) {
          params.append(
            `filters[${index}].DateRange.toDate`,
            filter.value.toDate
          );
        }
      } else {
        params.append(`filters[${index}].Value`, filter.value);
      }
    });

    const url = `${host}/${v}/Notification/paginated?${params.toString()}`;
    return get(url);
  },

  /* Tag */
  getAllSettings: async () => get(`${host}/${v}/Setting`),

  // POST methods
  createDevice: async (device) => post(`${host}/${v}/Device`, device),
  sendKeepalive: async () => post(`${host}/${v}/KeepAlive`),
  sendOperatorNotification: async (notification) => {
    return { isSuccess: true, data: "Notifica inviata!" };
  },
  createOperator: async (user) => post(`${host}/${v}/Keycloak/users`, user),
  createGroup: async (group) => post(`${host}/${v}/Keycloak/groups`, group),
  notifyEventsManagers: async (id) =>
    post(`${host}/${v}/Notification/resend/${id}`),
  sendNotification: async (notification) =>
    post(`${host}/${v}/Notification/sendTopic`, notification),
  updateEventHistory: async (history) =>
    post(`${host}/${v}/EventHistory/`, history),
  setDeviceGroup: async (deviceId, groupId, permission) =>
    post(`${host}/${v}/DeviceGroup`, {
      deviceId: deviceId,
      groupId: groupId,
      permissionType: permission,
    }),
  keycloakLogout: async (refreshToken) =>
    post(`${host}/${v}/Keycloak/logout`, refreshToken),

  /* Notification */
  sendNotificationAgain: async (id) =>
    post(`${host}/${v}/Notification/send/${id}`, {}),

  // PUT methods
  editDevice: async (device) => put(`${host}/${v}/Device`, device),
  updateOperator: async (user) =>
    put(`${host}/${v}/Keycloak/users/${user.user.username}`, user),
  updateGroup: async (group) => {
    dataStore.groups = dataStore.groups.map((gr) =>
      group.name === gr.name ? group : gr
    );
    return { isSuccess: true, data: dataStore.groups };
  },
  updateVariable: async (variable) => put(`${host}/${v}/Variable`, variable),
  updateSAVariable: async (variable) =>
    put(`${host}/${v}/Variable/sa`, variable),
  resetAllSAVariable: async (variableId, histories) =>
    put(`${host}/${v}/Variable/resetAllSA?variableId=${variableId}`, histories),
  resetSAVariable: async (variable) =>
    put(`${host}/${v}/Variable/reset-sa`, variable),
  updateSettings: async (settings) => put(`${host}/${v}/Setting`, settings),

  // DELETE methods
  removeUser: async (username) =>
    put(`${host}/${v}/Keycloak/users/${username}/disable`),
  removeDevice: async (deviceId) => del(`${host}/${v}/Device/${deviceId}`),
};

export default API;
