import React, { useEffect, useState } from "react";

import Keycloak from "keycloak-js";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import { useKeycloak } from "contexts/stores";
import { useUsernameStore } from "contexts/stores";
import { useLoggedAccountStores } from "contexts/stores";
import routes from "./routes";
import NotFound from "views/not-found";
import Dashboard from "./layouts/index";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Config = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: "Progress-Rail",
  clientId: "prr-frontend",
};

export default function KeycloakLogin() {
  //const navigate = useNavigate();
  const [keycloakInstance, setKeycloakInstance] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const setkeycloak = useKeycloak((state) => state.setkeycloak);
  const setUsername = useUsernameStore((state) => state.setUsername);
  const setLoggedAccountRoles = useLoggedAccountStores(
    (state) => state.setRoles
  );

  useEffect(() => {
    const webViewToken = localStorage.getItem("keycloak-token");
    if (webViewToken) {
      const keycloak = new Keycloak(Config);
      keycloak.token = webViewToken;
      setKeycloakInstance(keycloak); 
      const decodedToken = JSON.parse(atob(webViewToken.split('.')[1])); 
      setLoggedAccountRoles(decodedToken.realm_access.roles);
      setUsername(decodedToken.preferred_username);
      setkeycloak(keycloak);
      setKeycloakInstance(keycloak);
      localStorage.setItem("jwt_auth", webViewToken);
      setIsAuthenticated(true);

      keycloak.init({ promiseType: "native" }).then(() => {
        setkeycloak(keycloak);
        setIsAuthenticated(true);
      })
      .catch((error) => {
        console.error("Error initializing Keycloak", error);
        setIsAuthenticated(false);
      });
    } else {
      const keycloak = new Keycloak(Config);
      keycloak
        .init({ onLoad: "login-required", promiseType: "native" })
        .then((authenticated) => {
          setLoggedAccountRoles(keycloak.tokenParsed.realm_access.roles);
          setUsername(keycloak.tokenParsed.preferred_username);
          setkeycloak(keycloak);
          setKeycloakInstance(keycloak);
          setIsAuthenticated(authenticated);
          localStorage.setItem("jwt_auth", keycloak.token);
          setKeycloakInstance(keycloak);
        });
    }
  }, [setkeycloak, setUsername, setLoggedAccountRoles]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Dashboard />}>
        {routes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={
              // <ProtectedRoute roles={route.authRoles || []}>
              route.element
              // </ProtectedRoute>
            }
          />
        ))}
        <Route path="devices/:id" errorElement={<NotFound />}>
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/" element={<Navigate to="/devices" replace />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    )
  );

  if (keycloakInstance) {
    if (isAuthenticated) {
      return (
        <ThemeEditorProvider>
          <RouterProvider router={router} />
        </ThemeEditorProvider>
      );
    } else {
      return <div>Unable to authenticate!</div>;
    }
  }

  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      w={"100%"}
      minH={"100vh"}
    >
      <Flex alignItems={"center"}>
        <Spinner size={"xl"} />
        <Text pl="0.5rem" fontSize="4xl" as={"b"}>
          Initializing...
        </Text>
      </Flex>
    </Flex>
  );
}
