import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Flex,
  IconButton,
  Input,
  FormControl,
  Switch,
  useBreakpointValue,
  Center,
} from "@chakra-ui/react";
import ReactSelect from "react-select";
import { CloseIcon } from "@chakra-ui/icons";
import { FilterType } from "../FilterTypes";

const FieldFilters = ({
  filter,
  index,
  columnOptions,
  handleColumnChange,
  handleRemoveFilter,
  statusOptions,
  customStyles,
  submitFilter,
  submitDateFilter,
  submitBoolFilter,
  submitTimeoutInterval = 1000,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { t } = useTranslation();

  const selectedColumn = filter.column;
  const value = filter.value;

  const isDateType = selectedColumn?.type === FilterType.DATE;
  const isBoolType = selectedColumn?.type === FilterType.BOOL;
  const isStatusType = selectedColumn?.type === FilterType.STATUS;

  const [localValue, setLocalValue] = useState(value || "");
  const [submitTimeout, setSubmitTimeout] = useState(null);

  useEffect(() => {
    if (submitTimeout) {
      clearTimeout(submitTimeout);
    }

    const timeoutId = setTimeout(() => {
      submitFilter(localValue, index);
    }, submitTimeoutInterval);

    setSubmitTimeout(timeoutId);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [localValue, index, submitTimeoutInterval]);

  useEffect(() => {
    if (!isDateType && !isBoolType && !isStatusType) {
      setLocalValue(value || "");
    }
  }, [value, isDateType, isBoolType, isStatusType]);

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      submitFilter(localValue, index);
    }
  };
  const handleInputBlur = (e) => {
    // Quando l'input perde il focus, aggiorniamo il filtro
    if (!isDateType && !isBoolType && !isStatusType) {
      submitFilter(localValue, index);
    }
  };

  return (
    <Flex
      direction={isMobile ? "column" : "row"}
      gap="2"
      mb="5px"
      alignItems={isMobile ? "flex-start" : "center"}
    >
      {/* Selezione colonna */}
      <Flex direction="column" gap="1">
        {t("column_filter.select_column")}:
        <ReactSelect
          menuPlacement="bottom"
          options={columnOptions}
          value={columnOptions.find(
            (option) => option.value === selectedColumn?.id
          )}
          onChange={(option) => handleColumnChange(option, index)}
          placeholder={t("column_filter.select_column")}
          styles={customStyles}
        />
      </Flex>

      {/* Valore filtro */}
      <Flex direction="column" gap="1">
        {t("column_filter.filter")}:
        <Flex direction={isMobile ? "row" : "unset"} gap="1">
          {/* Gestione tipi di filtro */}
          {isStatusType ? (
            // STATUS
            <ReactSelect
              options={
                statusOptions.find(
                  (column) => column.key === selectedColumn?.id
                )?.values
              }
              onChange={(option) => submitFilter(option.value, index)}
              value={
                statusOptions
                  .find((column) => column.key === selectedColumn?.id)
                  ?.values.find((opt) => opt.value === value) || null
              }
              placeholder={t("column_filter.select_status")}
              styles={customStyles}
            />
          ) : isDateType ? (
            // DATE: value è un oggetto {fromDate, toDate}
            <Flex maxW={"180px"} direction={"column"}>
              {t("date.from")}:
              <Input
                type={"datetime-local"}
                value={value?.fromDate || ""}
                focusBorderColor="black"
                backgroundColor={"#EEEEEE"}
                placeholder={t("column_filter.filter")}
                onChange={(e) =>
                  submitDateFilter(e.target.value, "from", index)
                }
                width={"100%"}
                maxWidth={"200px"}
                mb={1}
              />
              {t("date.to")}:
              <Input
                type={"datetime-local"}
                value={value?.toDate || ""}
                focusBorderColor="black"
                backgroundColor={"#EEEEEE"}
                placeholder={t("column_filter.filter")}
                onChange={(e) => submitDateFilter(e.target.value, "to", index)}
                width={"100%"}
                maxWidth={"200px"}
              />
            </Flex>
          ) : isBoolType ? (
            // BOOLEAN: value è un boolean
            <Center margin="10px">
              <FormControl display="flex" alignItems="center">
                <Switch
                  id="boolean-switch"
                  isChecked={!!value}
                  onChange={(e) => {
                    submitBoolFilter(e.target.checked, index);
                  }}
                  colorScheme="teal"
                  size="lg"
                />
              </FormControl>
            </Center>
          ) : (
            // DEFAULT (testo)
            <Input
              value={localValue}
              focusBorderColor="black"
              backgroundColor={"#EEEEEE"}
              placeholder={t("column_filter.filter")}
              onChange={(e) => setLocalValue(e.target.value)}
              onBlur={handleInputBlur}
              onKeyDown={handleInputKeyDown}
              width={"100%"}
              maxWidth={"200px"}
            />
          )}

          {/* Pulsante per rimuovere il filtro (solo per filtri aggiuntivi) */}
          <IconButton
            aria-label="Remove filter"
            icon={<CloseIcon />}
            onClick={() => handleRemoveFilter(index)}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FieldFilters;
