import React from "react";

import { Icon } from "@chakra-ui/react";
import DvrIcon from "@mui/icons-material/Dvr";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import SpaceDashboardRoundedIcon from "@mui/icons-material/SpaceDashboardRounded";
import StraightenIcon from '@mui/icons-material/Straighten';

// Admin Imports
import DevicesPage from "views/devices";
import EventsPage from "views/events";
import OperatorsPage from "views/operators";
import MainDashboard from "views/dashboard";
import PermissionsPage from "views/permissions";
import MeasuresTable from "views/measures";
import SettingsPage from "views/settings";
import { ToggleProvider } from "components/externalComponents/ToggleContext";
import NotificationPage from "views/notifications/NotificationPage";
import StatisticTable from "./views/statistics";

const routes = [
  {
    name: "sidebar.dashboard",
    path: "/dashboard",
    icon: (
      <Icon
        as={SpaceDashboardRoundedIcon}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    element: <MainDashboard />,
  },
  {
    name: "sidebar.events",
    path: "events",
    icon: (
      <Icon
        as={ListAltOutlinedIcon}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    element: <ToggleProvider><EventsPage /></ToggleProvider>,
  },
  {
    name: "sidebar.devices",
    path: "devices",
    icon: <Icon as={DvrIcon} width="20px" height="20px" color="inherit" />,
    element: <DevicesPage />,
  },
  {
    name: "sidebar.operators",
    path: "operators",
    icon: (
      <Icon
        as={PeopleAltOutlinedIcon}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    element: <OperatorsPage />,
    secondary: true,
  },
  // {
  //   name: "sidebar.alarms",
  //   icon: (
  //     <Icon
  //       as={NotificationsActiveOutlinedIcon}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   path: "alarms",
  //   // element: <DataTables />,
  // },

  // {
  //   name: "sidebar.tickets",
  //   path: "tickets",
  //   icon: (
  //     <Icon
  //       as={ConfirmationNumberOutlinedIcon}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   // element: SignInCentered,
  // },
  // {
  //   name: "sidebar.permissions",
  //   path: "permissions",
  //   icon: (
  //     <Icon
  //       as={VpnKeyOutlinedIcon}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   element: <PermissionsPage />,
  // },
  {
    name: "sidebar.measures",
    path: "measures",
    icon: (
      <Icon
        as={StraightenIcon}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    element: <MeasuresTable />
  },
  {
    name: "sidebar.statistics",
    path: "statistics",
    icon: (
      <Icon
        as={CandlestickChartIcon}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    element: <StatisticTable />
  }, 
  {
    name: "sidebar.notifications",
    path: "notification",
    icon: (
      <Icon 
        as={NotificationsActiveOutlinedIcon}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    element: <NotificationPage />
  },
  {
    name: "sidebar.settings",
    path: "settings",
    icon: (
      <Icon
        as={SettingsOutlinedIcon}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    element: <ToggleProvider><SettingsPage /></ToggleProvider>,
  }
];

export default routes;
