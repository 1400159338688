import "assets/css/App.css";
import React, { useEffect, useState } from "react";
import useSignalR from "./utils/UseSignalR";
import ReactDOM from "react-dom";
import API from "./api/API";
import theme from "theme/theme";
import KeycloakLogin from "keycloakLogin";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  useMobileApp,
  useEventsStore,
  useCallbacks,
  translationsStore,
  useOperatorsStore,
} from "./contexts/stores";
import { config } from "config";
import { useTranslation } from "react-i18next";
import { ChakraProvider } from "@chakra-ui/react";
import i18n from "./i18n";

const App = () => {
  const [keepAliveInterval, setKeepaliveInterval] = useState(null);
  const { messages: signalRMessages } = useSignalR(
    config.endpoints.notification
  );

  const [mqttMessages, setMqttMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const { fetchEvents } = useEventsStore();

  const {
    fetchOperators,
    setPollingOperatorsInterval,
    clearPollingOperatorsInterval,
  } = useOperatorsStore();
  const { t } = useTranslation();

  // const { init } = useMqtt();
  const { setCallbacks } = useCallbacks();
  const { setTFunction } = translationsStore();
  const { setIsMobileApp, isMobileApp } = useMobileApp();

  useEffect(() => {
    const isMobile = !!localStorage.getItem("is-mobile");
    setIsMobileApp(isMobile);
    console.log("APP: " + isMobile);
  }, []);

  useEffect(() => {
    setTFunction(t);
  }, [setTFunction, t]);

  useEffect(() => {
    fetchOperators();
    setPollingOperatorsInterval();
    return () => {
      clearPollingOperatorsInterval();
    };
  }, [
    fetchOperators,
    setPollingOperatorsInterval,
    clearPollingOperatorsInterval,
  ]);

  useEffect(() => {
    const isMobile = !!localStorage.getItem("is-mobile");
    const intervalId = setInterval(() => {
      if (!isMobile) {
        API.sendKeepalive();
      }
    }, config.timers.keepAlive);
    if (!isMobile) {
      API.sendKeepalive();
    } 
    setKeepaliveInterval(intervalId);
    return () => {
      clearInterval(intervalId);
    };
  }, [isMobileApp]);

  useEffect(() => {
    if (!isMobileApp) {
      if (signalRMessages && signalRMessages.length > 0) {
        var mes = [...messages, signalRMessages[signalRMessages.length - 1]];
        setMessages(mes);
      }
    }
  }, [signalRMessages, isMobileApp]);

  useEffect(() => {
    setCallbacks("device", (message) => {
      var mes = [...messages, message];
      setMqttMessages(mes);
    });
    // init(mqttConfig);
  }, [/*init,*/ setCallbacks, setMqttMessages]);

  useEffect(() => {
    if (!isMobileApp) {
      // Controlla se Notification � disponibile
      if (typeof Notification !== "undefined") {
        if (Notification.permission === "default") {
          Notification.requestPermission().then((permission) => {
            if (permission !== "granted") {
              console.warn("Permission for notifications was denied.");
            }
          });
        }
      }

    if (messages && messages.length > 0) {
      const mess = JSON.parse(messages[messages.length - 1]);
      if (mess.type === "event") {
        toast(`[${mess.device}]: ${mess.description}`, {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            backgroundColor: "#FF0000",
            color: "#FFFFFF"
          },
          progressStyle: {
            background: "#ffd00d",
          },
          onOpen: () => {
            const sound = new Audio("/notification1.wav");
            sound.play();
          },
        });
        
        if (Notification.permission === "granted") {
          new Notification(mess.device, { body: mess.description, icon: "/favicon.ico" });
        }
        // Solo se Notification è definito
        if (typeof Notification !== "undefined" && Notification.permission === "granted") {
          new Notification(mess.device, { body: mess.description, icon: "/favicon.ico" });
        }

          fetchEvents();
        }
      }
    }
  }, [messages, fetchEvents]);

  return (
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <KeycloakLogin tab="home" />
        <ToastContainer />
      </React.StrictMode>
    </ChakraProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
