import React, { useEffect, useState } from "react";
import { Flex, Box, useBreakpointValue } from "@chakra-ui/react";
import FieldFilters from "./filterComponents/FilterField";
import FilterActions from "./filterComponents/FilterActions";
import { useTranslation } from "react-i18next";
import { isEqual } from "utils/isEqual";

const ColumnFilter = ({
  columns,
  pageType,
  applyFilter,
  setIsFilterActive,
  statusOptions,
  setFilters,
  filters,
  gotoPage,
  disableColumnFilter,
}) => {
  const { t } = useTranslation();
  const [activeCustomFilters, setActiveCustomFilters] = useState("");

  const setUniqueFilters = (newFilters) => {
    const uniqueFilters = [];
    const seen = new Set();

    for (const f of newFilters) {
      const colId = f.column?.id || "null_column_id";
      const valStr = JSON.stringify(f.value);
      const filterKey = `${colId}-${valStr}`;

      if (!seen.has(filterKey)) {
        seen.add(filterKey);
        uniqueFilters.push(f);
      }
    }
    setFilters(uniqueFilters);
  };

  // Effetto per gestire l'applicazione di filtri iniziali
  useEffect(() => {
    if (
      applyFilter &&
      columns.length > 0 &&
      !isEqual(applyFilter, activeCustomFilters)
    ) {
      setActiveCustomFilters(applyFilter);
      const initialFilters = Array.isArray(applyFilter)
        ? applyFilter
        : [applyFilter];
      const mappedFilters = initialFilters.map((f) => {
        const column = columns.find((col) => col.id === f.column) || {
          id: f.column,
        };
        return {
          column,
          value:
            column?.type === "date"
              ? {
                  fromDate: f.value?.fromDate || "",
                  toDate: f.value?.toDate || "",
                }
              : f.value,
        };
      });
      const newFilters = [...mappedFilters];
      setUniqueFilters(newFilters);
    }
  }, [applyFilter]);

  const handleColumnChange = (selectedOption, index) => {
    gotoPage(0);
    const newFilters = [...filters];
    columns.forEach((col) => col.setFilter && col.setFilter());
    const column = columns.find((col) => col.id === selectedOption.value);
    newFilters[index] = {
      column,
      value: column?.type === "date" ? { fromDate: "", toDate: "" } : "",
    };
    setUniqueFilters(newFilters);
  };

  const handleFilterChange = (value, index) => {
    const newFilters = [...filters];
    newFilters[index].value = value;
    setUniqueFilters(newFilters);
  };

  const handleDateFilterChange = (value, dateType, index) => {
    const newFilters = [...filters];
    if (
      typeof newFilters[index].value !== "object" ||
      newFilters[index].value === ""
    ) {
      newFilters[index].value = { fromDate: "", toDate: "" };
    }

    if (dateType === "from") {
      newFilters[index].value.fromDate = value;
    } else if (dateType === "to") {
      newFilters[index].value.toDate = value;
    }

    setUniqueFilters(newFilters);
  };

  const handleBoolFilterChange = (value, index) => {
    const newFilters = [...filters];
    newFilters[index].value = value;
    setUniqueFilters(newFilters);
  };

  const handleAddFilter = () => {
    const newFilters = [...filters, { column: null, value: "" }];
    setUniqueFilters(newFilters);
  };

  const handleRemoveFilter = (index) => {
    setActiveCustomFilters("");
    columns.forEach((col) => col.setFilter && col.setFilter());
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    setUniqueFilters(newFilters);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minWidth: "150px",
      maxWidth: "200px",
      backgroundColor: "#EEEEEE",
      borderColor: "black",
    }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
  };

  useEffect(() => {
    setIsFilterActive(
      filters.length > 0 &&
        !filters.every((f) => [null, undefined, ""].includes(f.value))
    );
  }, [filters, setIsFilterActive]);

  return (
    <Box>
      <Flex direction="column" gap="2">
        {filters.map((filter, index) => (
          <FieldFilters
            key={index}
            filter={filter}
            index={index}
            columnOptions={columns
              .filter((col) => !disableColumnFilter?.includes(col.id))
              .map((col) => ({
                value: col.id,
                label: t(`${pageType}.${col.Header}`),
              }))}
            handleColumnChange={handleColumnChange}
            submitFilter={handleFilterChange}
            submitDateFilter={handleDateFilterChange}
            submitBoolFilter={handleBoolFilterChange}
            handleRemoveFilter={handleRemoveFilter}
            statusOptions={statusOptions}
            customStyles={customStyles}
          />
        ))}

        <FilterActions handleAddFilter={handleAddFilter} />
      </Flex>
    </Box>
  );
};

export default ColumnFilter;
