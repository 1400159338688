import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  Tooltip
} from "@chakra-ui/react";
import NotifyOperator from "./NotifyOperator";
import SliderPopup from "components/slider/SliderPopup";
import { useOperatorsStore } from "contexts/stores";
import UpdateOperatorLayout from "../operatorHistory/UpdateOperatorLayout";
import OperatorCard from "./OperatorCard";
import { isOperatorAvailable } from "utils/mapUser";
import { t } from "i18next";




const search = (text, data) => {
  if (!text) return data;

  return data.filter((operator) => {
    return (
      operator.firstName?.toLowerCase().includes(text.toLowerCase()) ||
      operator.location?.toLowerCase().includes(text.toLowerCase()) ||
      operator.lastName?.toLowerCase().includes(text.toLowerCase()) ||
      operator.number?.toLowerCase().includes(text.toLowerCase()) ||
      operator.groups?.some((group) =>
        group.toLowerCase().includes(text.toLowerCase())
      )
    );
  });
};

function OperatorCardGrid({ data, searchText }) {
  const { operators } = useOperatorsStore();
  const [filteredData, setFilteredData] = useState(
    data.map((r) => {
      return r.original;
    })
  );

  const [isModalOpen, setIsModalOpen] = useState(
    new Array(operators?.length).fill(false)
  );
  const [isModalNotificationOpen, setIsModalNotificationOpen] = useState(
    new Array(operators?.length).fill(false)
  );
  const [selectedOperatorIndex, setSelectedOperatorIndex] = useState(null);

  const handleOperatorSelect = (index) => {
    setSelectedOperatorIndex(index);
    setIsModalOpen((prev) =>
      prev.map((isOpen, i) => (i === index ? true : isOpen))
    );
  };

  const handleCloseModal = () => {
    setIsModalOpen((prev) =>
      prev.map((isOpen, i) => (i === selectedOperatorIndex ? false : isOpen))
    );
    setSelectedOperatorIndex(null);
  };

  const handleNotificationSelect = (index, event) => {
    event.stopPropagation();
    setSelectedOperatorIndex(index);
    setIsModalNotificationOpen((prev) =>
      prev.map((isOpen, i) => (i === index ? true : isOpen))
    );
  };

  const handleCloseNotificationModal = () => {
    setIsModalNotificationOpen((prev) =>
      prev.map((isOpen, i) => (i === selectedOperatorIndex ? false : isOpen))
    );
    setSelectedOperatorIndex(null);
  };

  useEffect(() => {
    setFilteredData(
      data.map((r) => {
        return r.original;
      })
    );
  }, [data]);

  useEffect(() => {
    setIsModalOpen(new Array(filteredData?.length).fill(false));
  }, [filteredData?.length]);


  return (
    <Grid
      templateColumns="repeat(auto-fill, minmax(120px, 1fr))"
      gap={6}
      m={{ base: "10px", md: "20px" }}
    >
      {filteredData?.map((operator, index) => (
        <GridItem key={index}>
          {
            operator.availability === isOperatorAvailable.AVAILABLE ? (
              <Tooltip label={`${t("available_until")} ${operator.availabilityEnd.slice(0, operator.availabilityEnd.indexOf(":") + 3)}`}
              placement="top">
                <Box>
                  <OperatorCard operator={operator} index={index} handleOperatorSelect={handleOperatorSelect} handleNotificationSelect={handleNotificationSelect} />
                </Box>
              </Tooltip>
            ) : (
              <OperatorCard operator={operator} index={index} handleOperatorSelect={handleOperatorSelect} handleNotificationSelect={handleNotificationSelect} />
            )
          }
          <SliderPopup isOpen={isModalOpen[index]} onClose={handleCloseModal}>
            <UpdateOperatorLayout
              onClose={handleCloseModal}
              operatorData={filteredData[selectedOperatorIndex]}
            />
          </SliderPopup>

          <SliderPopup
            isOpen={isModalNotificationOpen[index]}
            onClose={handleCloseNotificationModal}
          >
            <NotifyOperator
              operatorData={operator}
              onClose={handleCloseNotificationModal}
            />
          </SliderPopup>
        </GridItem>
      ))}
    </Grid>
  );
}

export default OperatorCardGrid;
