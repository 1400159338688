import { format, parseISO } from "date-fns";
import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { useTranslation } from "react-i18next";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

const colorStatus = [
  "white",
  "#ffd00d", // Yellow
  "#F44336", // Red
  "lightgrey",
];

const colorIcons = [
  <DarkModeOutlinedIcon />,
  <PriorityHighIcon />,
  <PriorityHighIcon />,
  <SettingsOutlinedIcon />,
];

const TimelineComponent = ({ logs }) => {
  const { t } = useTranslation();

  return (
    <VerticalTimeline lineColor="#EEEEEE">
      {logs.map((history) => (
        <VerticalTimelineElement
          contentStyle={{
            borderTop: `4px solid ${colorStatus[history.isError]}`,
          }}
          contentArrowStyle={{
            borderRight: `7px solid  ${colorStatus[history.isError]}`,
          }}
          key={history.id}
          date={
            <div className="timeline-date">
              {format(parseISO(history.time + "Z"), "yyyy-MM-dd")}
              <br />
              {format(parseISO(history.time + "Z"), "HH:mm:ss")}
            </div>
          }
          iconStyle={{
            background: colorStatus[history.isError],
            color: "#000",
            border: history.isError === 0 ? "4px solid lightblue" : "",
          }}
          icon={colorIcons[history.isError]}
        >
          <h3
            className="vertical-timeline-element-title"
            style={{ fontWeight: "bold", fontSize: "20px" }}
          >
            {" "}
            {t(`variable_history.status.${history.isError}`)}
          </h3>
          <h4
            className="vertical-timeline-element-subtitle"
            style={{ fontSize: "18px" }}
          >
            {history.kName}
          </h4>
          <h4
            className="vertical-timeline-element-subtitle"
            style={{ fontWeight: "bold" }}
          >
            {history.firstName} {history.lastName}
          </h4>
        </VerticalTimelineElement>
      ))}
    </VerticalTimeline>
  );
};

export default TimelineComponent;
