import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Flex,
  Icon,
  Button,
  Switch,
  Grid,
  GridItem,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import {
  buildTextList,
  colorMap,
  formatDate,
  getDataTypes,
} from "../TableContent";
import { useNavigate } from "react-router-dom";
import SliderPopup from "components/slider/SliderPopup";

//per EventsTableContent
const bgColor = "pcr.200";
const hoverColor = "pcr.100";

const MobileTable = ({
  data,
  pageType,
  selectOptions,
  onOptionChange,
  textColor,
  prepareRow,
  hideColumnsOnLoad,
  columnsList,
  onToggleChange,
  toggleStates,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [openPopupState, setOpenPopupState] = useState({
    isOpen: false,
    component: null,
  });

  const splitLongText = (text, limit) => {
    if (!text || typeof text !== "string") return text;
    const regex = new RegExp(`(.{1,${limit}})(\\s|$)`, "g");
    return text.match(regex).join("\n");
  };

  const handleOpen = (row, component) => {
    setSelectedRow(row);
    setOpenPopupState({
      isOpen: true,
      component,
    });
  };

  const handleClose = () => {
    setOpenPopupState({
      isOpen: false,
      component: null,
    });
    setSelectedRow(null);
  };

  const formatValue = (cell) => {
    let type = getDataTypes(cell?.column?.type);
    const renderer = renderers[type] || defaultRenderer;
    return renderer(cell, textColor);
  };

  const defaultRenderer = (cell, textColor) => (
    <Text color={textColor} fontSize="md" fontWeight="400">
      {cell?.value || "-"}
    </Text>
  );

  const renderers = {
    date: (cell, textColor) => (
      <Text color={textColor} fontSize="md" fontWeight="400">
        {formatDate(cell?.value, pageType) || "-"}
      </Text>
    ),
    text: (cell, textColor) => (
      <Text color={textColor} fontSize="md" fontWeight="400">
        {cell?.value || "-"}
      </Text>
    ),
    textIcon: (cell, textColor) => (
      <Box display={"flex"}>
        <Text color={textColor} fontSize="md" fontWeight="400">
          {cell?.value || "-"}
        </Text>
        <Icon fontSize={"large"}>{cell?.column?.icon}</Icon>
      </Box>
    ),
    textList: (cell, textColor) => {
      return buildTextList(cell, textColor);
    },
    textListCustom: (cell, textColor) => {
      if (
        cell?.value.renderInfo?.main &&
        cell?.value.renderInfo?.tooltip &&
        cell?.value.value
      ) {
        return buildTextList(
          cell?.value,
          textColor,
          cell?.value.renderInfo.main,
          cell?.value.renderInfo.tooltip,
          " > "
        );
      } else {
        return buildTextList(cell, textColor);
      }
    },
    textTooltip: (cell, textColor) => {
      return (
        <Tooltip label={cell.value.tooltip}>
          <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
            {cell.value.main || "-"}
          </Text>
        </Tooltip>
      );
    },
    status: (cell, textColor) => (
      <>
        <Text color={textColor} fontSize="md" fontWeight="400">
          {cell?.value !== null && cell?.value !== undefined
            ? t(`${pageType}.status_list.${cell?.value}`)
            : "-"}
        </Text>
      </>
    ),
    enabled: (cell, textColor) =>
      selectOptions ? (
        <div onClick={(e) => e.stopPropagation()}>
          <ReactSelect
            menuPlacement="bottom"
            options={selectOptions}
            onChange={(selectedOption) => {
              onOptionChange(cell?.row.original, selectedOption);
            }}
            value={
              selectOptions.find((option) => option.value === cell?.value) ||
              null
            }
            placeholder={t(`${pageType}.select_severity`)}
            styles={{
              control: (base) => ({
                ...base,
                minWidth: "150px",
                maxWidth: "200px",
                backgroundColor: "#EEEEEE",
                borderColor: "black",
                fontSize: "1.2em",
              }),
              menu: (base) => ({ ...base, fontSize: "1.2em" }),
            }}
          />
        </div>
      ) : (
        <Text color={textColor} fontSize="md" fontWeight="400">
          {cell?.value + ""} {/*? t(`operators_list.enabled`) : "-"} */}
        </Text>
      ),
    type: (cell, textColor) => (
      <Text color={textColor} fontSize="md" fontWeight="400">
        {t(`${pageType}.types.${cell?.value}`) || "-"}
      </Text>
    ),
  };

  const formatColumnsValue = (columnList, row) => {
    switch (columnList.accessor) {
      case "button":
        return !!columnList.customButton ? (
          columnList.customButton(row)
        ) : (
          <Button
            key={row.index}
            bgColor={bgColor}
            _hover={{ bgColor: hoverColor }}
            // ml={"5px"}
            textColor="black"
            onClick={(e) => {
              columnList.onClick(row);
              e.stopPropagation();
            }}
          >
            {t(columnList.Header)}
          </Button>
        );
      case "buttonPanel":
        return (
          <Button
            key={row.index}
            bgColor={bgColor}
            _hover={{ bgColor: hoverColor }}
            // ml={"5px"}
            textColor="black"
            onClick={(e) => {
              handleOpen(row, columnList.sliderPopupComponent);
              e.stopPropagation();
            }}
          >
            {t(columnList.Header)}
          </Button>
        );
      case "buttonOptional":
        return columnList.condition(row) ? (
          <Button
            key={row.index}
            bgColor={bgColor}
            _hover={{ bgColor: hoverColor }}
            // ml={"5px"}
            textColor="black"
            onClick={(e) => {
              handleOpen(row, columnList.sliderPopupComponent);
              e.stopPropagation();
            }}
          >
            {t(columnList.Header)}
          </Button>
        )
          : (
            <></>
          );
      case "redirectButton":
        return (
          <Button
            key={row.index}
            bgColor={bgColor}
            _hover={{ bgColor: hoverColor }}
            ml={"5px"}
            textColor="black"
            onClick={(e) => {
              navigate(columnList.path, { state: { id: row.original.id } });
              e.stopPropagation();
            }}
          >
            {t(columnList.Header)}
          </Button>
        );
      case "toggle":
        return (
          <Flex direction="column">
            <Flex pt="5px">
              <Switch
                isChecked={toggleStates[row.original.kName] || false}
                onChange={(e) =>
                  onToggleChange(row.original.kName, e.target.checked)
                }
              />
            </Flex>
          </Flex>
        );
      default:
        return <span>{columnList.Header}</span>;
    }
  };

  const renderStatusColor = (row) => {
    const statusValue = row.allCells.find(
      (cell) => getDataTypes(cell?.column?.type) === "status"
    )?.value;

    if (statusValue !== undefined && statusValue !== null) {
      return colorMap[pageType][statusValue];
    }
    return "white";
  };

  return (
    <Box mt={10} overflow={"hidden"}>
      <Accordion allowToggle>
        {data.map((row, index) => {
          prepareRow(row);
          //{...row.getRowProps()}
          return (
            <AccordionItem
              key={index}
              borderRadius="20px"
              bgColor={renderStatusColor(row)}
              borderBottom={"1px"}
              boxShadow={"lg"}
              transition="box-shadow 0.2s ease"
              mb={2}
            >
              <h2>
                <AccordionButton borderRadius="20px" p={2}>
                  <Grid
                    templateColumns="1fr 2fr"
                    gap={4}
                    width="100%"
                    textAlign={"left"}
                  >
                    {row.cells.map((cell) => {
                      if (hideColumnsOnLoad[cell.column.Header])
                        return (
                          <>
                            <GridItem>
                              <Text fontWeight="bold" whiteSpace="pre-wrap">
                                {t(`${pageType}.${cell.column.Header}`)}:
                              </Text>
                            </GridItem>
                            <GridItem>
                              <Text whiteSpace="pre-wrap">
                                {formatValue(cell)}
                              </Text>
                            </GridItem>
                          </>
                        );
                    })}
                  </Grid>
                  <AccordionIcon />
                </AccordionButton>
              </h2>

              <AccordionPanel gap={3}>
                <Flex
                  key={index}
                  justify="space-between"
                  width="100%"
                  direction={"column"}
                >
                  <Grid templateColumns="1fr 2fr" gap={4}>
                    {row.cells.map((cell) => {
                      if (!hideColumnsOnLoad[cell.column.Header])
                        return (
                          <>
                            <GridItem>
                              <Text fontWeight="bold">
                                {t(`${pageType}.${cell.column.Header}`)}:
                              </Text>
                            </GridItem>
                            <GridItem>{formatValue(cell)}</GridItem>
                          </>
                        );
                    })}
                  </Grid>

                  {columnsList.map((columList, index) => {
                    if (columList.page === pageType) {
                      return (
                        <Flex
                          key={columList.Header + index}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                          mt={1}
                          justifyContent={"center"}
                        >
                          {formatColumnsValue(columList, row)}
                        </Flex>
                      );
                    }
                    return null;
                  })}
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
      {openPopupState.isOpen && openPopupState.component && (
        <SliderPopup isOpen={openPopupState.isOpen} onClose={handleClose}>
          {openPopupState.component(handleClose, selectedRow)}{" "}
        </SliderPopup>
      )}
    </Box>
  );
};

export default MobileTable;
