export const columnsData = [
  {
    Header: "ID",
    accessor: "id",
    type: "id",
  },
  {
    Header: "Time",
    accessor: "time",
    type: "date",
  },
  {
    Header: "Location",
    accessor: "location",
    type: "text",
  },
  {
    Header: "Root Names",
    accessor: "rootNames",
    type: "text",
  },
  {
    Header: "Name",
    accessor: "name",
    type: "text",
  },
  {
    Header: "Level",
    accessor: "level",
    type: "text",
  },
  {
    Header: "Title",
    accessor: "title",
    type: "text",
  },
  {
    Header: "Message",
    accessor: "message",
    type: "text",
  },
  {
    Header: "status",
    accessor: "status",
    type: "status",
  },
  {
    Header: "Web Notification",
    accessor: "webNotification",
    type: "number",
  },
  {
    Header: "App Notification",
    accessor: "appNotification",
    type: "number",
  },
];
