import React, { useState } from "react";
import {
  Button,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Portal,
  useBreakpointValue,
} from "@chakra-ui/react";
import ColumnFilter from "./ColumnFilter";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTranslation } from "react-i18next";

const bgColor = "pcr.200";
const hoverColor = "pcr.100";
const bgColorActive = "black";
const textColorActive = "pcr.200";
const iconColorActive = "#ffd00d";

export default function FilterButton({
  allColumns,
  tableData,
  pageType,
  applyFilter,
  statusOptions,
  isInDrawer,
  dependencyArray,
  isVisible = true,
  setFilters = (filters) => {},
  filters,
  gotoPage,
  disableColumnFilter,
}) {
  const { t } = useTranslation();
  const [isFilterActive, setIsFilterActive] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    allColumns.length > 0 && (
      <Popover closeOnBlur={!isMobile}>
        {isVisible && (
          <PopoverTrigger>
            <Button
              bgColor={isFilterActive ? bgColorActive : bgColor}
              _hover={{ bgColor: hoverColor }}
              ml={"5px"}
            >
              <FilterListIcon
                style={{
                  color: isFilterActive ? iconColorActive : bgColorActive,
                }}
              />
              <Text
                pl={"2px"}
                color={isFilterActive ? textColorActive : bgColorActive}
              >
                {t("column_filter.button")}
              </Text>
            </Button>
          </PopoverTrigger>
        )}

        {isInDrawer ? (
          <PopoverContent
            borderColor={"black"}
            width={isMobile ? "300px" : "auto"}
          >
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>{t("column_filter.title")}</PopoverHeader>
            <PopoverBody>
              <ColumnFilter
                columns={allColumns.filter((col) => col.canFilter)}
                data={tableData}
                pageType={pageType}
                applyFilter={applyFilter}
                setIsFilterActive={setIsFilterActive}
                statusOptions={statusOptions}
                dependencyArray={dependencyArray}
                setFilters={setFilters}
                filters={filters}
                gotoPage={gotoPage}
                disableColumnFilter={disableColumnFilter}
              />
            </PopoverBody>
          </PopoverContent>
        ) : (
          <Portal>
            <PopoverContent
              borderColor={"black"}
              width={isMobile ? "300px" : "auto"}
            >
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>{t("column_filter.title")}</PopoverHeader>
              <PopoverBody>
                <ColumnFilter
                  columns={allColumns.filter((col) => col.canFilter)}
                  data={tableData}
                  pageType={pageType}
                  applyFilter={applyFilter}
                  setIsFilterActive={setIsFilterActive}
                  statusOptions={statusOptions}
                  dependencyArray={dependencyArray}
                  setFilters={setFilters}
                  filters={filters}
                  gotoPage={gotoPage}
                  disableColumnFilter={disableColumnFilter}
                />
              </PopoverBody>
            </PopoverContent>
          </Portal>
        )}
      </Popover>
    )
  );
}
