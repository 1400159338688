export function convertToString(obj) {
  if (Array.isArray(obj)) {
    return obj.map(convertToString).join(", ");
  } else if (typeof obj === "object" && obj !== null) {
    return Object.entries(obj)
      .map(([key, value]) => {
        return `${key}: ${convertToString(value)}`;
      })
      .join(", ");
  } else if (typeof obj === "string" && isValidDate(obj)) {
    return formatTimestamp(obj);
  } else {
    return String(obj);
  }
}

function formatTimestamp(isoString) {
  const date = new Date(isoString);
  const pad = (num, size) => String(num).padStart(size, "0");

  const day = pad(date.getDate(), 2);
  const month = pad(date.getMonth() + 1, 2);
  const year = date.getFullYear();

  const hours = pad(date.getHours(), 2);
  const minutes = pad(date.getMinutes(), 2);
  const seconds = pad(date.getSeconds(), 2);
  const milliseconds = pad(date.getMilliseconds(), 2);

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

function isValidDate(dateString) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

export const checkTranslation = (message, key, result) => {
  if (message === key) return result;
  else return message;
};
