import { useTranslation } from "react-i18next";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button, Spinner, Text } from "@chakra-ui/react";
import { formatDate } from "./TableContent";
import { useMeasuresStore } from "contexts/stores";
import { useEventsStore } from "contexts/stores";
import { useState } from "react";

const bgColor = "pcr.200";
const hoverColor = "pcr.100";
const offset = 0;
const limit = 0;

export default function ExportButton({ columns, rows, allColumns, pageType }) {
  const { t } = useTranslation();
  const { filters, fetchMeasurePage } = useMeasuresStore();
  const { eventsFetchMeasurePage, eventsFilters } = useEventsStore((state) => ({
    eventsFetchMeasurePage: state.fetchEventsPage,
    eventsFilters: state.filters,
  }));

  const [loading, setLoading] = useState(false);

  const exportToCSV = async (columns, data) => {
    setLoading(true);
    let result = [];

    if (pageType === "raw_data_list") {
      result = await fetchMeasurePage(offset, limit, "", [], filters)?.data;
      result = result?.data;
    }
    if (pageType === "events_list") {
      result = await eventsFetchMeasurePage(
        offset,
        limit,
        "",
        [],
        eventsFilters
      );
      result = result?.data;
    }
    const visibleColumns = columns.filter((column) =>
      allColumns.some((col) => col.isVisible && col.Header === column.Header)
    );

    const convertToCSV = (data, columns) => {
      const header = columns
        .map((col) => t(`${pageType}.${col.Header}`))
        .join(";");
      const rows = data.map((row) =>
        columns
          .map((col) => {
            let value = null;
            if (col.type === "status") {
              value =
                result.length > 0
                  ? t(`${pageType}.status_list.${row[col.accessor]}`)
                  : t(`${pageType}.status_list.${row.values[col.accessor]}`);
            } else {
              value =
                result.length > 0
                  ? row[col.accessor]
                  : row.values[col.accessor];
            }
            if (col.type === "date") {
              return formatDate(value);
            }
            return value;
          })
          .join(";")
      );
      return [header, ...rows].join("\n");
    };

    const csv =
      result.length > 0
        ? convertToCSV(result, visibleColumns)
        : convertToCSV(data, visibleColumns);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a link element and simulate a click to download the file
    const link = document.createElement("a");
    link.href = url;

    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}_${month}_${day}`;

    link.setAttribute(
      "download",
      formattedDate + "_" + t(`export_name.${pageType}`) + ".csv"
    );
    setLoading(false);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button
      onClick={() => exportToCSV(columns, rows)}
      bgColor={bgColor}
      _hover={{ bgColor: hoverColor }}
      ml={"5px"}
    >
      {loading ? <Spinner /> : <FileDownloadIcon />}
      <Text pl={"2px"}>{t("export")}</Text>
    </Button>
  );
}
