import React from "react";
import { Box, SimpleGrid, useBreakpointValue } from "@chakra-ui/react";
import CreateDevice from "../CreateDevice";
import DeviceHistory from "./DeviceHistory";

const UpdateDeviceLayout = ({ onClose, deviceData, deviceId }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      height={!isMobile ? "100%" : "90%"}
      overflowY={isMobile ? "auto" : "hidden"}
    >
      <SimpleGrid
        columns={isMobile ? 1 : 2}
        spacing={4}
        height={isMobile ? "auto" : "100%"}
      >
        <Box
          p={4}
          borderRadius="md"
          boxShadow="md"
          overflowY="auto"
          height={isMobile ? "auto" : "100%"}
        >
          <CreateDevice onClose={onClose} deviceData={deviceData} />
        </Box>
        <Box
          p={4}
          borderRadius="md"
          boxShadow="md"
          overflowY={"hidden"}
          height={isMobile ? "auto" : "100%"}
        >
          <DeviceHistory onClose={onClose} deviceId={deviceId} />
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default UpdateDeviceLayout;
