// DeviceCardGrid.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Icon,
  Tooltip,
  Flex,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import SliderPopup from "components/slider/SliderPopup";
import UpdateDeviceLayout from "./DeviceHistory/UpdateDeviceLayout";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useTranslation } from "react-i18next";
import EventsPage from "views/events";
import { ToggleProvider } from "components/externalComponents/ToggleContext";
import DeviceHistory from "./DeviceHistory/DeviceHistory";
import { useEventsStore, useMobileApp } from "contexts/stores";

const maxLengthDeviceName = 10;
const maxLengthLocation = 12;
const maxLengthDeviceType = 12;

export const DevIsAlive = {
  OK: 0,
  WARNING: 1,
  ERROR: 2,
};

export const truncateString = (str, maxLength) => {
  if (!str) return "";
  if (str.length <= maxLength) {
    return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
  } else
    return <Tooltip label={str}>{str.slice(0, maxLength) + "..."}</Tooltip>;
};

const cardColors = {
  [DevIsAlive.OK]: "green.500",
  [DevIsAlive.WARNING]: "yellow",
  [DevIsAlive.ERROR]: "red.500",
};

const cardTextColors = {
  [DevIsAlive.OK]: "black",
  [DevIsAlive.WARNING]: "black",
  [DevIsAlive.ERROR]: "black",
};

const search = (text, data) => {
  if (!text) return data;

  return data.filter((device) => {
    return (
      device.name.toLowerCase().includes(text.toLowerCase()) ||
      device.location.toLowerCase().includes(text.toLowerCase()) ||
      device.version.toLowerCase().includes(text.toLowerCase()) ||
      device.lastModificationDateTime
        .toLowerCase()
        .includes(text.toLowerCase()) ||
      device.tags.some((tag) =>
        tag.name.toLowerCase().includes(text.toLowerCase())
      )
    );
  });
};

function DeviceCardGrid({ data, searchText, deleteDevice = (deviceId) => {} }) {
  const [filteredData, setFilteredData] = useState(
    data.map((r) => {
      return r.original;
    })
  );
  const { t } = useTranslation();
  const { isMobileApp } = useMobileApp();
  const [eventFilter, setEventFilter] = useState([]);
  const events = useEventsStore();

  const [isModalOpen, setIsModalOpen] = useState(
    new Array(data.length).fill(false)
  );
  const [isModalOpen2, setIsModalOpen2] = useState(
    new Array(data.length).fill(false)
  );
  const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(null);

  const [isTooltipOpen, setIsTooltipOpen] = useState({});

  const handleDeviceSelect = (index) => {
    setSelectedDeviceIndex(index);
    setIsModalOpen((prev) =>
      prev.map((isOpen, i) => (i === index ? true : isOpen))
    );
  };
  const handleDeviceSelect2 = (index) => {
    setSelectedDeviceIndex(index);
    setIsModalOpen2((prev) =>
      prev.map((isOpen, i) => (i === index ? true : isOpen))
    );
  };

  const handleCloseModal = () => {
    setIsModalOpen((prev) =>
      prev.map((isOpen, i) => (i === selectedDeviceIndex ? false : isOpen))
    );
    setSelectedDeviceIndex(null);
  };
  const handleCloseModal2 = () => {
    setEventFilter([]);
    setIsModalOpen2((prev) =>
      prev.map((isOpen, i) => (i === selectedDeviceIndex ? false : isOpen))
    );
    setSelectedDeviceIndex(null);
  };

  const handleClick = (eventId) => {
    if (Array.isArray(eventId) && eventId?.length > 0) {
      setEventFilter(
        eventId.map((id) => {
          return {
            column: "id",
            value: id,
          };
        })
      );
    } else {
      setEventFilter({
        column: "id",
        value: eventId,
      });
    }
  };

  const getDeviceFieldStatusStyle = (fieldStatus) => {
    switch (fieldStatus) {
      case 0:
        return {
          bgGradient: "linear(to-br, green.300, green.500)",
          boxShadow: "0 0 15px rgba(72, 187, 120, 0.6)",
        };
      case 1:
        return {
          bgGradient: "linear(to-br, red.300, red.500)",
          boxShadow: "0 0 15px rgba(245, 101, 101, 0.6)",
        };
      case 2:
        return {
          bgGradient: "linear(to-br, gray.300, gray.500)",
          boxShadow: "0 0 15px rgba(160, 160, 160, 0.6)",
        };
      default:
        return {
          bgGradient: "linear(to-br, gray.300, gray.500)",
          boxShadow: "0 0 15px rgba(160, 160, 160, 0.6)",
        };
    }
  };

  useEffect(() => {
    setFilteredData(
      data.map((r) => {
        return r.original;
      })
    );
  }, [data]);

  useEffect(() => {
    setIsModalOpen(new Array(filteredData.length).fill(false));
    setIsModalOpen2(new Array(filteredData.length).fill(false));
  }, [filteredData.length]);

  return (
    <Grid
      templateColumns="repeat(auto-fill, minmax(140px, 1fr))"
      justifyContent={"space-between"}
      m={isMobileApp ? "5px" : "20px"}
      alignItems={"center"}
      justifyItems={"center"}
      mt={"20px"}
    >
      {filteredData.map((device, index) => (
        <GridItem key={index} style={{ userSelect: "none" }}>
          <Tooltip
            label={
              Array.isArray(device?.tags)
                ? device?.tags
                    .map((tag) => (typeof tag === "object" ? tag.name : tag))
                    .join(", ")
                : device?.tags.toString()
            }
            openDelay={300}
            placement="top"
            isOpen={isMobileApp && !!isTooltipOpen[index]}
          >
            <Box
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="130px"
              height="130px"
              {...getDeviceFieldStatusStyle(device.fieldStatus)}
              _hover={{
                transform: "scale(1.1)",
                transition: "transform 0.2s ease-in-out",
              }}
              onTouchStart={() =>
                setIsTooltipOpen((prev) => ({ ...prev, [index]: true }))
              }
              onTouchEnd={() =>
                setIsTooltipOpen((prev) => ({ ...prev, [index]: false }))
              }
            >
              <Box
                key={index}
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                bg={cardColors[device?.alive]}
                p="5px"
                textAlign="center"
                boxShadow="md"
                border="1px"
                width="120px"
                height="120px"
                onClick={() => {
                  handleDeviceSelect2(index);
                }}
                cursor="pointer"
              >
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color={cardTextColors[device?.alive]}
                >
                  {truncateString(device?.name, maxLengthDeviceName)}
                </Text>
                <Text color={cardTextColors[device?.alive]}>
                  {truncateString(device?.location, maxLengthLocation)}
                </Text>
                <Text color={cardTextColors[device?.alive]} pt={"10px"}>
                  {truncateString(device?.type, maxLengthDeviceType)}
                </Text>
                {!isMobileApp && (
                  <Flex
                    pt={"10px"}
                    justifyContent={isMobileApp ? "center" : "space-between"}
                    alignItems={"center"}
                  >
                    <Tooltip
                      label={
                        Array.isArray(device?.tags)
                          ? device?.tags
                              .map((tag) =>
                                typeof tag === "object" ? tag.name : tag
                              )
                              .join(", ")
                          : device?.tags.toString()
                      }
                      openDelay={300}
                      closeOnClick
                      placement="top"
                    >
                      <Icon
                        color={cardTextColors[device?.alive]}
                        onTouchStart={(e) => {
                          e.preventDefault();
                          e.stopPropagation(); // Prevenire comportamenti indesiderati
                        }}
                      >
                        <SellOutlinedIcon />
                      </Icon>
                    </Tooltip>
                    <Tooltip label={t("details")}>
                      <Icon
                        color={cardTextColors[device?.alive]}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeviceSelect(index);
                        }}
                      >
                        <SearchOutlinedIcon />
                      </Icon>
                    </Tooltip>
                  </Flex>
                )}
                <SliderPopup
                  key={index}
                  isOpen={isModalOpen[index]}
                  onClose={handleCloseModal}
                >
                  <UpdateDeviceLayout
                    onClose={handleCloseModal}
                    deviceData={device}
                    deviceId={device?.id}
                  />
                </SliderPopup>

                <SliderPopup
                  key={index + " 2"}
                  isOpen={isModalOpen2[index]}
                  onClose={handleCloseModal2}
                >
                  <Flex
                    height={"35vh"}
                    width={"100%"}
                    justifyContent={"center"}
                  >
                    <DeviceHistory
                      deviceId={device?.id}
                      horizontal={true}
                      onClick={handleClick}
                      fetchHistory={events}
                      reset={() => setEventFilter([])}
                    />
                  </Flex>
                  <ToggleProvider>
                    <EventsPage
                      applyFilter={[
                        {
                          column: "deviceRoots",
                          value: device?.name,
                        },
                      ].concat(eventFilter)}
                      isInDrawer={true}
                    />
                  </ToggleProvider>
                </SliderPopup>
              </Box>
            </Box>
          </Tooltip>
        </GridItem>
      ))}
    </Grid>
  );
}

export default DeviceCardGrid;
