import React, { useState } from "react";
import { useStatisticStore } from "../../contexts/stores";
import { Box } from "@chakra-ui/react";
import FiltersTable from "../../components/filtersTable/FiltersTable";
import LoadingSpinner from "components/generic/LoadingSpinner";
import { useTranslation } from "react-i18next";
import ThresholdTable from "./components/ThresholdTable";
import { Search2Icon } from '@chakra-ui/icons'

const StatisticTable = ({ applyFilter, isInDrawer = false }) => {
  const { fetchStatisticPage, isUpdating } = useStatisticStore(); // Fetch function
  const { t } = useTranslation();
  const [statisticThreshold, setStatisticThreshold] = useState([]);

  const columnsVariables = [
    {
      Header: "timeStart",
      accessor: "timeStart",
      type: "date",
    },
    {
      Header: "timeEnd",
      accessor: "timeEnd",
      type: "date",
    },
    {
      Header: "rootType",
      accessor: "rootType",
      type: "text",
    },
    {
      Header: "deviceRoots",
      accessor: "deviceRoots",
      type: "text",
    },
    {
      Header: "deviceId",
      accessor: "deviceId",
      type: "text",
    },
    {
      Header: "variableId",
      accessor: "variableId",
      type: "text",
    },
    {
      Header: "variableName",
      accessor: "variableName",
      type: "text",
    },
    {
      Header: "mean",
      accessor: "mean",
      type: "text",
    },
    {
      Header: "variance",
      accessor: "variance",
      type: "text",
    },
    {
      Header: "samples",
      accessor: "samples",
      type: "text",
    },
  ];

  const columnsList = [
    {
      Header: "details",
      icon: <Search2Icon/>,
      accessor: "buttonOptional",
      condition: (row) => {
        return statisticThreshold[row?.original?.statisticId]?.length > 0;
      },
      page: "statistic_data_list",
      sliderPopupComponent: (handleClose, row) => {
        return (
          <>
            <ThresholdTable
              data={statisticThreshold[row?.original?.key]}
              isInDrawer={true}
            />
          </>
        );
      },
    },
  ];

  // Define the fetchPage function
  const fetchPageData = async (
    pageIndex,
    itemsPerPage,
    globalFilter,
    sortBy,
    filters
  ) => {
    try {
      const result = await fetchStatisticPage(
        pageIndex,
        itemsPerPage,
        globalFilter,
        sortBy,
        filters
      );
      setStatisticThreshold(
        result.data.reduce((acc, d) => {
          acc[d?.statistic?.statisticId] = d.thresholds;
          return acc;
        }, {})
      );
      return {
        data: result.data.map((d) => d?.statistic),
        totalItems: result.totalItems,
      };
    } catch (error) {
      console.error("Error fetching page data:", error);
      return { data: [], totalItems: 0 };
    }
  };

  // Define pagination object
  const pagination = {
    fetchPage: fetchPageData,
  };

  return isUpdating ? (
    <LoadingSpinner />
  ) : (
    <Box bgColor="white" padding="15px" shadow="lg">
      <FiltersTable
        columnsList={columnsList}
        columnsVariables={columnsVariables}
        pagination={pagination}
        pageType={"statistic_data_list"}
        hideColumnsOnLoad={{
          deviceId: false,
          deviceName: true,
          deviceRoots: true,
          key: false,
          mean: true,
          rootType: true,
          samples: true,
          statisticId: false,
          timeEnd: true,
          timeStart: true,
          variableId: false,
          variableName: true,
          variance: true,
        }}
        paginated={true}
        refreshButton={true}
        applyFilter={applyFilter ?? ""}
        isInDrawer={isInDrawer}
        isVisible={!isInDrawer ?? true}
        navigateToPage={isInDrawer ? "/statistic" : ""}
      />
    </Box>
  );
};

export default StatisticTable;
