import React from "react";
import { useMeasuresStore } from "../../contexts/stores";
import { Box } from "@chakra-ui/react";
import FiltersTable from "../../components/filtersTable/FiltersTable";
import LoadingSpinner from "components/generic/LoadingSpinner";
import { useTranslation } from "react-i18next";
import { columnsRawData } from "./variables/columnsData";
import { ToggleProvider } from "components/externalComponents/ToggleContext";
import EventsPage from "views/events";

const MeasuresTable = ({ applyFilter, isInDrawer = false }) => {
  const { fetchMeasurePage, isUpdating } = useMeasuresStore(); // Fetch function
  const { t } = useTranslation();

  const columnsVariables = columnsRawData;
  const columnsList = [
    {
      Header: "event_link",
      accessor: "buttonOptional",
      condition: (row) => {
        return row?.original?.event;
      },
      page: "raw_data_list",
      sliderPopupComponent: (handleClose, row) => {
        return (
          <>
            <ToggleProvider>
              <EventsPage
                applyFilter={[
                  {
                    column: "id",
                    value: row?.original?.eventId,
                  },
                ]}
                isInDrawer={true}
              />
            </ToggleProvider>
          </>
        );
      },
    },
  ];

  const renderDevices = (cell) => {
    return {
      timestamp: cell.timestamp,
      rootType: cell.rootType.split(" > ")[0],
      deviceRoots: {
        main: cell.deviceRoots,
        tooltip: cell.rootType,
      },
      device: cell.deviceName,
      deviceType: cell.deviceType,
      value: cell.value,
      variable: cell.variableName,
      variableDescription: cell.variableDescription,
      eventId: cell.eventID,
      type: cell.variableType,
    };
  };

  // Define the fetchPage function
  const fetchPageData = async (
    pageIndex,
    itemsPerPage,
    globalFilter,
    sortBy,
    filters
  ) => {
    try {
      const result = await fetchMeasurePage(
        pageIndex,
        itemsPerPage,
        globalFilter,
        sortBy,
        filters
      );
      return {
        data: result.data.map((d) => renderDevices(d)),
        totalItems: result.totalItems,
      };
    } catch (error) {
      console.error("Error fetching page data:", error);
      return { data: [], totalItems: 0 };
    }
  };

  // Define pagination object
  const pagination = {
    fetchPage: fetchPageData,
  };

  return isUpdating ? (
    <LoadingSpinner />
  ) : (
    <Box bgColor="white" padding="15px" shadow="lg">
      <FiltersTable
        columnsList={columnsList}
        columnsVariables={columnsVariables}
        pagination={pagination}
        pageType={"raw_data_list"}
        hideColumnsOnLoad={{
          timestamp: true,
          rootType: true,
          device: true,
          deviceRoots: true,
          variable: false,
          variableDescription: true,
          value: true,
          deviceType: true,
        }}
        paginated={true}
        refreshButton={true}
        applyFilter={applyFilter ?? ""}
        isInDrawer={isInDrawer}
        isVisible={!isInDrawer ?? true}
        navigateToPage={isInDrawer ? "/measures" : ""}
      />
    </Box>
  );
};

export default MeasuresTable;
