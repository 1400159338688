import React, { useState } from "react";
import {
  FormControl,
  Input,
  FormErrorMessage,
  Textarea,
  Box,
  Text,
  FormLabel,
  Flex,
  Grid,
  Switch,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const inputBgColor = "#EEEEEE";
const inputPlaceholderColor = "gray";

const FormInput = ({
  name,
  type = "text",
  placeholder,
  control,
  register,
  errors,
  isDisabled = false,
  isPhoneInput = false,
  isSelect = false,
  selectOptions = [],
  validationRules = {},
  isReadOnly,
  isTextArea,
  isBoolean,
  directionColumn,
  labelExpanded,
}) => {
  const [charCount, setCharCount] = useState(0);
  const maxLength = 300;
  const { t } = useTranslation();

  return (
    <FormControl p={"0px"} isInvalid={errors[name]}>
      <Grid
        templateColumns={{
          base: "1fr",
          md:
            isTextArea || directionColumn
              ? "1fr"
              : labelExpanded
              ? "400px 1fr"
              : "150px 1fr",
        }}
        gap={1}
        mb={4}
      >
        <Flex alignItems="center">
          <FormLabel htmlFor={name} mb={0}>
            {placeholder}
          </FormLabel>
        </Flex>
        <Flex direction={"column"}>
          {isPhoneInput ? (
            <Box ml={2} width={"100%"}>
              <Controller
                name={name}
                control={control}
                rules={validationRules}
                render={({ field }) => (
                  <PhoneInput
                    country={"it"}
                    value={field.value}
                    onChange={field.onChange}
                    inputStyle={{
                      width: "100%",
                      backgroundColor: isReadOnly ? inputBgColor : "white",
                      marginLeft: "8px",
                    }}
                    buttonStyle={{
                      backgroundColor: isReadOnly ? inputBgColor : "white",
                    }}
                    disabled={isReadOnly}
                  />
                )}
              />
            </Box>
          ) : isSelect ? (
            <Box width={"100%"}>
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={selectOptions}
                    isMulti
                    menuPlacement="top"
                    placeholder={placeholder}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: isReadOnly ? inputBgColor : "white",
                        width: "100%",
                        marginLeft: "8px",
                      }),
                    }}
                    onChange={(selected) => field.onChange(selected)}
                    isDisabled={isReadOnly}
                  />
                )}
              />
            </Box>
          ) : isTextArea ? (
            <Box mt={2} width={"100%"}>
              <Controller
                name={name}
                control={control}
                rules={validationRules}
                render={({ field }) => (
                  <Textarea
                    maxLength={maxLength}
                    readOnly={isReadOnly}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    isDisabled={isDisabled}
                    isReadOnly={isReadOnly}
                    bgColor={isReadOnly ? inputBgColor : "white"}
                    onChange={(e) => {
                      setCharCount(e.target.value.length);
                      field.onChange(e.target.value);
                    }}
                    sx={{
                      "::placeholder": {
                        color: inputPlaceholderColor,
                      },
                    }}
                  />
                )}
              />
              <Text fontSize="sm" textAlign="right">
                {maxLength - charCount} {t(`char_remaining`)}
              </Text>
            </Box>
          ) : isBoolean ? (
            <Controller
              name={name}
              control={control}
              rules={validationRules}
              render={({ field }) => (
                <Switch
                  ml={2}
                  id={name}
                  isChecked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                  isReadOnly={isReadOnly}
                  isDisabled={isReadOnly}
                />
              )}
            />
          ) : (
            <Input
              focusBorderColor="black"
              bgColor={isReadOnly || isDisabled ? inputBgColor : "white"}
              name={name}
              type={type}
              placeholder={placeholder}
              isDisabled={isDisabled}
              isReadOnly={isReadOnly}
              {...register(name, validationRules)}
              sx={{
                "::placeholder": {
                  color: inputPlaceholderColor,
                },
                marginLeft: "8px",
              }}
            />
          )}
          <FormErrorMessage>
            {errors[name] && errors[name].message}
          </FormErrorMessage>
        </Flex>
      </Grid>
    </FormControl>
  );
};

export default FormInput;
