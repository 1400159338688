import { format } from "date-fns";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import DoneIcon from "@mui/icons-material/Done";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

const colorStatus = [
  "#4CAF50", // Green
  "#ffd00d", // Yellow
  "#F44336", // Red
];

const colorIcons = [DoneIcon, MoreHorizTwoToneIcon, PriorityHighIcon];

const HorizontalTimeline = ({ logs, onClick, reset }) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(null);

  return (
    <Flex direction="row" overflowX="auto" align="center" pb={4}>
      {logs.length > 0 &&
        logs.map((log, index) => (
          <Box
            key={log.changeTime}
            bg="#fff"
            border="1px solid #E0E0E0"
            borderRadius="8px"
            m={2}
            p={2}
            minWidth="150px"
            minH="150px"
            maxH="150px"
            textAlign="center"
            position="relative"
            bgColor={selectedIndex === index ? "#64baf8" : "#fff"}
          >
            <Text fontWeight="bold" mb={1}>
              {format(new Date(log.changeTime), "yyyy-MM-dd")}
              <br />
              {format(new Date(log.changeTime), "HH:mm:ss")}
            </Text>

            <Flex direction="column" align="center">
              {/* TODO: fix filter */}
              <Flex //Button
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                boxShadow="md"
                width="40px"
                height="40px"
                border="1px solid lightgray"
                bgColor={colorStatus[log.status]}
                margin="0 auto"
                position="relative"
                zIndex={1500}
                // onClick={() => {
                //   if (selectedIndex === index) {
                //     reset();
                //     setSelectedIndex(null);
                //   } else {
                //     onClick(log.eventIds);
                //     setSelectedIndex(index);
                //   }
                // }}
              >
                <Icon as={colorIcons[log.status]} boxSize={6} color="#fff" />
              </Flex>

              {/* Line connecting to the next box */}
              {index < logs.length && (
                <Box
                  position="absolute"
                  left="60%"
                  top="55%"
                  height="10px"
                  bg="gray.300"
                  width={index + 1 === logs.length ? "0%" : "100%"}
                  transform="translateY(-50%)"
                  zIndex={1000}
                />
              )}
            </Flex>

            <Text fontWeight="bold" fontSize="18px" mt={1} maxWidth="150px">
              {t(`device_list.status_list2.${log.status}`)}
            </Text>
          </Box>
        ))}
    </Flex>
  );
};

export default HorizontalTimeline;
