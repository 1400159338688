import React, { useEffect, useState } from "react";
import {
  Button,
  FormLabel,
  Input,
  Select,
  VStack,
  useBreakpointValue,
  Grid,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import API from "api/API";
import { checkTranslation } from "utils/StringConverter";

const inputBgColor = "#EEEEEE";
const inputPlaceholderColor = "gray";

const ConfigurationForm = () => {
  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const fetchSettings = async () => {
    const result = await API.getAllSettings();

    if (result.isSuccess) {
      const mappedData = result.data.reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
      }, {});

      const transformedData = {
        ...mappedData,
        baseAggregation: convertFromMinutes(mappedData.baseAggregation, "days"),
        baseAggregationTimeUnit: "days",
        dbPersistence: convertFromMinutes(mappedData.dbPersistence, "days"),
        dbPersistenceTimeUnit: "days",
        rawDataPersistence: convertFromMinutes(
          mappedData.rawDataPersistence,
          "days"
        ),
        rawDataPersistenceTimeUnit: "days",
        logFilePersistence: convertFromMinutes(
          mappedData.logFilePersistence,
          "days"
        ),
        logFilePersistenceTimeUnit: "days",
      };

      reset(transformedData);
    } else {
      console.error("Errore durante il recupero delle impostazioni.");
    }
  };

  useEffect(() => {
    fetchSettings();
  }, [reset]);

  useEffect(() => {
    fetchSettings();
  }, []);

  const convertToMinutes = (val, unit) => {
    switch (unit) {
      case "hours":
        return val * 60;
      case "days":
        return val * 60 * 24;
      case "months":
        return val * 60 * 24 * 31;
      default:
        return Math.round(val);
    }
  };

  const convertFromMinutes = (val, unit) => {
    switch (unit) {
      case "hours":
        return val / 60;
      case "days":
        return val / (60 * 24);
      case "months":
        return val / (60 * 24 * 31);
      default:
        return Math.round(val);
    }
  };

  const handleTimeUnitChange = (field, currentValue, newUnit) => {
    const currentUnit = watch(field + "TimeUnit");

    const convertedValue = convertFromMinutes(
      convertToMinutes(currentValue, currentUnit),
      newUnit
    );

    setValue(field, convertedValue);
    setValue(field + "TimeUnit", newUnit);
  };

  const onSubmit = (data) => {
    const baseAggregationMinutes = convertToMinutes(
      data.baseAggregation,
      data.baseAggregationTimeUnit
    );
    const rawDataPersistenceMinutes = convertToMinutes(
      data.rawDataPersistence,
      data.rawDataPersistenceTimeUnit
    );

    const dbPersistenceMinutes = convertToMinutes(
      data.dbPersistence,
      data.dbPersistenceTimeUnit
    );

    if (
      isUpdating &&
      baseAggregationMinutes < rawDataPersistenceMinutes &&
      dbPersistenceMinutes >= rawDataPersistenceMinutes
    ) {
      const settingsFormatted = [
        {
          name: "baseAggregation",
          value: convertToMinutes(
            data.baseAggregation,
            data.baseAggregationTimeUnit
          ),
        },
        {
          name: "rawDataPersistence",
          value: convertToMinutes(
            data.rawDataPersistence,
            data.rawDataPersistenceTimeUnit
          ),
        },
        {
          name: "dbPersistence",
          value: convertToMinutes(
            data.dbPersistence,
            data.dbPersistenceTimeUnit
          ),
        },
        {
          name: "sessionTimeout",
          value: data.sessionTimeout,
        },
        {
          name: "notifyTimeout",
          value: data.notifyTimeout,
        },
        {
          name: "logFilePersistence",
          value: convertToMinutes(
            data.logFilePersistence,
            data.logFilePersistenceTimeUnit
          ),
        },
      ];

      API.updateSettings(settingsFormatted)
        .then((response) => {
          if (response.isSuccess) {
            enqueueSnackbar(t("settings_form.success"), {
              variant: "success",
            });
          } else {
            enqueueSnackbar(
              checkTranslation(
                t(`settings_form.${response.errorMsg || response.data}`),
                "settings_form.${response.errorMsg || response.data}",
                t("settings_form.error")
              ),
              {
                variant: "error",
              }
            );
          }
        })
        .catch((error) => {
          console.error("Error updating settings:", error);
        });
    }
    if (
      baseAggregationMinutes < rawDataPersistenceMinutes &&
      dbPersistenceMinutes >= rawDataPersistenceMinutes
    ) {
      setIsUpdating(!isUpdating);
    } else {
      if (dbPersistenceMinutes < rawDataPersistenceMinutes) {
        enqueueSnackbar(t("settings_form.dbPersistence_error"), {
          variant: "error",
        });
      }
      if (baseAggregationMinutes >= rawDataPersistenceMinutes) {
        enqueueSnackbar(t("settings_form.baseAggregationTime_error"), {
          variant: "error",
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4} align="stretch">
        {/* Base Temporale  */}
        <Grid
          templateColumns={isMobile ? "1fr" : "1fr 1fr 1fr"}
          gap={4}
          alignItems="center"
        >
          <FormLabel gridColumn={isMobile ? "span 3" : undefined}>
            {t("settings_form.base_aggregation")}
          </FormLabel>
          <Input
            ml={1.5}
            type="number"
            {...register("baseAggregation", {
              required: t("required"),
            })}
            placeholder={t("settings_form.base_aggregation")}
            isReadOnly={!isUpdating}
            bgColor={!isUpdating ? inputBgColor : "white"}
            focusBorderColor="black"
            sx={{ "::placeholder": { color: inputPlaceholderColor } }}
            isInvalid={(() => {
              const baseAggregationMinutes = convertToMinutes(
                watch("baseAggregation"),
                watch("baseAggregationTimeUnit")
              );
              const rawDataPersistenceMinutes = convertToMinutes(
                watch("rawDataPersistence"),
                watch("rawDataPersistenceTimeUnit")
              );
              return baseAggregationMinutes >= rawDataPersistenceMinutes;
            })()}
          />
          <Select
            {...register("baseAggregationTimeUnit")}
            bgColor={!isUpdating ? inputBgColor : "white"}
            focusBorderColor="black"
            onChange={(e) =>
              handleTimeUnitChange(
                "baseAggregation",
                watch("baseAggregation"),
                e.target.value
              )
            }
          >
            <option value="minutes">{t("minutes")}</option>
            <option value="hours">{t("hours")}</option>
            <option value="days">{t("days")}</option>
          </Select>
        </Grid>

        {/* Persistenza Dati Grezzi */}
        <Grid
          templateColumns={isMobile ? "1fr" : "1fr 1fr 1fr"}
          gap={4}
          alignItems="center"
        >
          <FormLabel>{t("settings_form.persistence")}</FormLabel>
          <Input
            ml={1.5}
            type="number"
            {...register("rawDataPersistence", {
              required: t("required"),
            })}
            placeholder={t("settings_form.persistence")}
            isReadOnly={!isUpdating}
            bgColor={!isUpdating ? inputBgColor : "white"}
            focusBorderColor="black"
            sx={{ "::placeholder": { color: inputPlaceholderColor } }}
            isInvalid={(() => {
              const baseAggregationMinutes = convertToMinutes(
                watch("baseAggregation"),
                watch("baseAggregationTimeUnit")
              );
              const rawDataPersistenceMinutes = convertToMinutes(
                watch("rawDataPersistence"),
                watch("rawDataPersistenceTimeUnit")
              );

              const dbPersistenceMinutes = convertToMinutes(
                watch("dbPersistence"),
                watch("dbPersistenceTimeUnit")
              );
              return (
                baseAggregationMinutes >= rawDataPersistenceMinutes &&
                dbPersistenceMinutes < rawDataPersistenceMinutes
              );
            })()}
          />
          <Select
            {...register("rawDataPersistenceTimeUnit")}
            bgColor={!isUpdating ? inputBgColor : "white"}
            focusBorderColor="black"
            onChange={(e) =>
              handleTimeUnitChange(
                "rawDataPersistence",
                watch("rawDataPersistence"),
                e.target.value
              )
            }
          >
            <option value="hours">{t("hours")}</option>
            <option value="days">{t("days")}</option>
          </Select>
        </Grid>
        {/* Persistenza DB */}
        <Grid
          templateColumns={isMobile ? "1fr" : "1fr 1fr 1fr"}
          gap={4}
          alignItems="center"
        >
          <FormLabel gridColumn={isMobile ? "span 3" : undefined}>
            {t("settings_form.db_persistence")}
          </FormLabel>
          <Input
            ml={1.5}
            type="number"
            {...register("dbPersistence", {
              required: t("required"),
            })}
            placeholder={t("settings_form.db_persistence")}
            isReadOnly={!isUpdating}
            bgColor={!isUpdating ? inputBgColor : "white"}
            focusBorderColor="black"
            sx={{ "::placeholder": { color: inputPlaceholderColor } }}
            isInvalid={(() => {
              const rawDataPersistenceMinutes = convertToMinutes(
                watch("rawDataPersistence"),
                watch("rawDataPersistenceTimeUnit")
              );

              const dbPersistenceMinutes = convertToMinutes(
                watch("dbPersistence"),
                watch("dbPersistenceTimeUnit")
              );
              return dbPersistenceMinutes < rawDataPersistenceMinutes;
            })()}
          />
          <Select
            {...register("dbPersistenceTimeUnit")}
            bgColor={!isUpdating ? inputBgColor : "white"}
            focusBorderColor="black"
            onChange={(e) =>
              handleTimeUnitChange(
                "dbPersistence",
                watch("dbPersistence"),
                e.target.value
              )
            }
          >
            <option value="hours">{t("hours")}</option>
            <option value="days">{t("days")}</option>
            <option value="months">{t("months")}</option>
          </Select>
        </Grid>

        {/* Timeout Sessione */}
        <Grid
          templateColumns={isMobile ? "1fr" : "1fr 2fr"}
          gap={4}
          alignItems="center"
        >
          <FormLabel>{t("settings_form.session_timeout")}</FormLabel>
          <Input
            type="number"
            {...register("sessionTimeout", { required: t("required") })}
            placeholder={t("settings_form.session_timeout")}
            isReadOnly={!isUpdating}
            bgColor={!isUpdating ? inputBgColor : "white"}
            focusBorderColor="black"
            sx={{ "::placeholder": { color: inputPlaceholderColor } }}
          />
        </Grid>

        {/* Timeout Rinvio Notifica */}
        <Grid
          templateColumns={isMobile ? "1fr" : "1fr 2fr"}
          gap={4}
          alignItems="center"
        >
          <FormLabel>{t("settings_form.notify_timeout")}</FormLabel>
          <Input
            type="number"
            {...register("notifyTimeout", { required: t("required") })}
            placeholder={t("settings_form.notify_timeout")}
            isReadOnly={!isUpdating}
            bgColor={!isUpdating ? inputBgColor : "white"}
            focusBorderColor="black"
            sx={{ "::placeholder": { color: inputPlaceholderColor } }}
          />
        </Grid>

        {/* Persistenza Log */}
        <Grid
          templateColumns={isMobile ? "1fr" : "1fr 1fr 1fr"}
          gap={4}
          alignItems="center"
        >
          <FormLabel gridColumn={isMobile ? "span 3" : undefined}>
            {t("settings_form.log_file_time")}
          </FormLabel>
          <Input
            ml={1.5}
            type="number"
            {...register("logFilePersistence")}
            focusBorderColor="black"
            bgColor={!isUpdating ? inputBgColor : "white"}
            placeholder={t("settings_form.log_file_time")}
            isReadOnly={!isUpdating}
            sx={{
              "::placeholder": {
                color: inputPlaceholderColor,
              },
            }}
          />
          <Select
            {...register("logFilePersistenceTimeUnit")}
            bgColor={!isUpdating ? inputBgColor : "white"}
            focusBorderColor="black"
            onChange={(e) =>
              handleTimeUnitChange(
                "logFilePersistence",
                watch("logFilePersistence"),
                e.target.value
              )
            }
          >
            <option value="minutes">{t("minutes")}</option>
            <option value="hours">{t("hours")}</option>
            <option value="days">{t("days")}</option>
          </Select>
        </Grid>

        <Button
          type="submit"
          bgColor={"pcr.200"}
          _hover={{ bgColor: "pcr.100" }}
          alignSelf="center"
          maxW="300px"
        >
          {isUpdating ? t("save") : t("edit")}
        </Button>
      </VStack>
    </form>
  );
};

export default ConfigurationForm;
