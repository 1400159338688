import React, { useState, useRef } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const ConfirmAction = ({ title, subtitle, triggerButton, onConfirm }) => {
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();
  const { t } = useTranslation();

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <>
      {triggerButton && React.cloneElement(triggerButton, { onClick: onOpen })}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{subtitle ?? t("confirm_info")}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {t("cancel")}
              </Button>
              <Button colorScheme="red" onClick={handleConfirm} ml={3}>
                {t("confirm")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ConfirmAction;
