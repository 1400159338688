import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Table,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";
import {
  usePagination,
  useSortBy,
  useTable,
  useFilters,
  useResizeColumns,
} from "react-table";
import Card from "components/card/Card";
import Pagination from "components/table/Pagination";
import { useTranslation } from "react-i18next";
import ColumnsButton from "./ColumnsButton";
import FilterButton from "./FilterButton";
import ExportButton from "./ExportButton";
import TableContent from "./TableContent";
import LoadingSpinner from "../generic/LoadingSpinner";
import { formatDate } from "./TableContent";
import { parseISO, isBefore, isAfter, parse, isEqual } from "date-fns";
import { useNavigate } from "react-router-dom";
import TableHeader from "./TableHeader";
import MobileTable from "./mobileApp/MobileTableView";
import { isEqual as compareArrays } from "utils/isEqual";
import { arrayFiltersAreEquals } from "utils/isEqual";

const defaultColumnMaxSize = 200;
const defaultColumnMinSize = 50;
const defaultColumnSize = 150;

export default function FiltersTable({
  tableData = [],
  isFiltersOn = false,
  isMapDashboard,
  pageType,
  columnsList = [],
  toggleStates,
  onToggleChange,
  columnsVariables,
  pagination = undefined,
  applyFilter,
  statusOptions,
  hideColumnsOnLoad,
  rowClick,
  cardGrid,
  isInDrawer,
  navigateToPage,
  isVisible,
  selectOptions,
  onOptionChange,
  paginated = false,
  refreshButton = false,
  refreshFunction,
  refreshPage,
  isMobileTable,
  disableColumnFilter,
  tableHeight,
  areButtonsInvisible = false,
  customTextStyle
}) {
  const { t } = useTranslation();
  const [columnsData, setColumnsData] = useState([]);
  const [tableDataString, setTableDataString] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  // const { width, height } = useWindowSize();
  const isMobile = useBreakpointValue({ base: true, md: false });

  // If refresh key changes it reloads current page
  const [refreshKey, setRefreshKey] = useState(0);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const navigate = useNavigate();
  const [isResizing, setIsResizing] = useState(false);
  const isResizingRef = useRef(false); // Stato persistente e sincronizzato
  const [activeInitialFilters, setActiveInitialFilters] = useState("");
  const [previousPageIndex, setPreviousPageIndex] = useState(null);

  // Stato per le colonne nascoste
  const [hiddenColumns, setHiddenColumns] = useState([]);

  // ******************CUSTOM FILTERS**********************
  const orFilter = (rows, columnId, filterValues) => {
    const filterValuesToLower = Array.isArray(filterValues)
      ? filterValues.map((val) => (val + "").toLowerCase())
      : [(filterValues + "").toLowerCase()];

    return rows.filter((row) => {
      const cellValue = (row.values[columnId] + "").toLowerCase();

      return filterValuesToLower.some((filterValue) => {
        return cellValue.includes(filterValue);
      });
    });
  };

  const equalsFilter = (rows, columnId, filterValues) => {
    const filterValuesToLower = Array.isArray(filterValues)
      ? filterValues
          .map((val) => (val + "").toLowerCase())
          .filter((val) => val !== "")
      : [(filterValues + "").toLowerCase()].filter((val) => val !== "");

    return filterValuesToLower.length === 0
      ? rows
      : rows.filter((row) => {
          const cellValue = (row.values[columnId] + "").toLowerCase();

          return filterValuesToLower.some((filterValue) => {
            return cellValue === filterValue || filterValue === "";
          });
        });
  };

  const dateRangeFilter = (rows, id, filterValues) => {
    const dateRanges = Array.isArray(filterValues)
      ? filterValues
      : [filterValues];

    return rows.filter((row) => {
      const cellValue = row.values[id];
      const date = parse(cellValue, "dd/MM/yyyy HH:mm:ss.SSS", new Date());

      if (isNaN(date.getTime())) {
        return false;
      }

      return dateRanges.some((val) => {
        const fromDate = val.fromDate ? parseISO(val.fromDate) : null;
        const toDate = val.toDate ? parseISO(val.toDate) : null;

        const isAfterFrom = fromDate
          ? isEqual(date, fromDate) || isAfter(date, fromDate)
          : true;
        const isBeforeTo = toDate
          ? isEqual(date, toDate) || isBefore(date, toDate)
          : true;
        return isAfterFrom && isBeforeTo;
      });
    });
  };

  const filterTypes = {
    date: dateRangeFilter,
    text: orFilter,
    id: equalsFilter,
  };
  const getFilterForType = (type) => {
    return filterTypes[type] || filterTypes["text"];
  };
  // ******************END CUSTOM FILTERS***********************

  useEffect(() => {
    if (tableData?.length > 0) {
      let properties = Object.keys(tableData[0]);
      let cols = properties.map((key) => {
        return {
          id: key, // Aggiungere l'ID per mantenere la consistenza
          Header: key,
          accessor: key,
          type:
            columnsVariables?.find((col) => col?.accessor === key)?.type ||
            tableData[0][key],
          filter: getFilterForType(
            columnsVariables?.find((col) => col?.accessor === key)?.type
          ),
          translationKey: columnsVariables?.find((col) => col?.accessor === key)
            ?.translationKey,
        };
      });
      setColumnsData(cols);
      let obj = tableData;
      for (let i = 0; i < obj.length; i++) {
        for (let key in obj[i]) {
          if (obj[i].hasOwnProperty(key)) {
            if (cols.find((col) => col.Header === key).type === "date") {
              obj[i][key] = formatDate(obj[i][key]);
            } else if (
              cols.find((col) => col.Header === key).type === "textList" &&
              Array.isArray(obj[i][key])
            ) {
              obj[i][key] = obj[i][key]?.map((opt) => opt?.name ?? opt);
            } else {
              obj[i][key] =
                obj[i][key] !== undefined && obj[i][key] !== null
                  ? obj[i][key]
                  : null;
            }
          }
        }
      }
      setTableDataString(obj);
    }
  }, [tableData, columnsVariables]);

  const getColumnWidth = (columnData) => {
    const maxLength = columnData.reduce((max, item) => {
      if (item === null) return;
      const itemLength = item?.toString().length;
      return itemLength > max ? itemLength : max;
    }, 0);

    const baseWidth = 50;
    const widthIncrement = 10;
    return baseWidth + maxLength * widthIncrement;
  };

  const columns = useMemo(() => {
    return columnsData.map((column) => {
      const columnData = tableDataString.map((row) => row[column.accessor]);
      const columnWidth = getColumnWidth(columnData);

      return {
        ...column,
        width: columnWidth,
        minWidth: defaultColumnMinSize,
        maxWidth: defaultColumnMaxSize,
      };
    });
  }, [columnsData, tableDataString]);

  const data = useMemo(() => tableDataString, [tableDataString]);

  // Conteggio delle pagine controllate (solo quando la paginazione è fornita)
  const [controlledPageCount, setControlledPageCount] = useState(0);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    toggleHideColumn,
    state: { pageIndex, pageSize, globalFilter, sortBy },
    rows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: hiddenColumns, // Inizializza le colonne nascoste
      },
      manualPagination: !!paginated,
      manualGlobalFilter: !!paginated,
      manualSortBy: !!paginated,
      autoResetPage: false,
      autoResetFilters: !!paginated,
      autoResetHiddenColumns: false, // Evita il reset delle colonne nascoste
      ...(pagination
        ? {
            pageCount: controlledPageCount,
          }
        : {}),
    },
    useFilters,
    useSortBy,
    usePagination,
    useResizeColumns
  );

  // Funzione per raggruppare i filtri per colonna
  const groupFiltersByColumnId = (filtersList) => {
    const filterGroups = {};
    filtersList.forEach((filter) => {
      const columnId = filter.column?.id;
      const value = filter.value;
      if (columnId) {
        if (!filterGroups[columnId]) {
          filterGroups[columnId] = { id: columnId, values: [] };
        }
        filterGroups[columnId].values.push(value);
      }
    });
    return Object.values(filterGroups);
  };

  const applyFilters = (newFilters) => {
    const filtersByColumnId = groupFiltersByColumnId(newFilters);
    filtersByColumnId.forEach((filterGroup) => {
      const { id, values } = filterGroup;
      const filterColumn = newFilters.find((f) => f.column?.id === id)?.column;
      if (filterColumn && filterColumn.setFilter) {
        if (values.length > 1) {
          filterColumn.setFilter(values);
        } else {
          // Gestione caso singolo valore, se 0 lo castiamo a stringa
          filterColumn.setFilter(values[0] === 0 ? "0" : values[0]);
        }
      }
    });
  };

  useEffect(() => {
    if (!arrayFiltersAreEquals(activeInitialFilters, filters) || refreshKey) {
      applyFilters(filters);
      if (applyFilter) setActiveInitialFilters([...filters]);
    }
  }, [filters]);

  // Fetch dei dati quando la paginazione è fornita (paginazione server-side)
  useEffect(() => {
    if (
      paginated &&
      pagination &&
      (!compareArrays(activeInitialFilters, filters) ||
        refreshKey ||
        previousPageIndex !== 0 ||
        canPreviousPage) &&
      !filters.some((f) => f.column === null)
    ) {
      const fetchData = async () => {
        setPreviousPageIndex(pageIndex);
        setLoading(true);
        const result = await pagination.fetchPage(
          pageIndex,
          pageSize,
          globalFilter,
          sortBy,
          filters.map((f) => ({
            column: f.column?.id,
            value: f.value,
          }))
        );
        const fetched = result.data;
        const totalItems = result.totalItems;
        // Aggiorna il conteggio delle pagine
        setControlledPageCount(Math.ceil(totalItems / pageSize));
        // Imposta columnsData se non già impostato
        let properties = [];
        try {
          properties = Object.keys(fetched[0]);
          const cols = properties.map((key) => ({
            id: key,
            Header: key,
            accessor: key,
            type:
              columnsVariables?.find((col) => col?.accessor === key)?.type ||
              fetched[0][key],
            filter: getFilterForType(
              columnsVariables?.find((col) => col?.accessor === key)?.type
            ),
            translationKey: columnsVariables?.find(
              (col) => col?.accessor === key
            )?.translationKey,
          }));
          setColumnsData(cols);

          // Processa i dati per assicurarsi che le proprietà esistano
          if (!applyFilter || (applyFilter.length > 0 && filters.length > 0)) {
            const processedData = fetched.map((item) =>
              Object.fromEntries(
                Object.entries(item).map(([key, value]) => [key, value ?? null])
              )
            );
            setTableDataString(processedData);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
        if (fetched.length === 0 && isInDrawer) {
          setColumnsData([]);
        } else if (fetched.length === 0) {
          setTableDataString([]);
        }
      };
      if (
        (isInDrawer && filters.length > 0) || //&&
        // !arrayFiltersAreEquals(activeInitialFilters, filters))
        !isInDrawer
      ) {
        fetchData();
      } else {
        if (applyFilter) {
          const newFilters = applyFilter.map((f) => {
            return {
              column: { id: f.column },
              value: f.value,
            };
          });
          setFilters(newFilters);
        }
      }
    }
  }, [
    paginated,
    pageIndex,
    pageSize,
    globalFilter,
    sortBy,
    filters,
    refreshKey,
    t,
  ]);

  // Gestisci i dati quando la paginazione non è fornita (paginazione client-side)
  useEffect(() => {
    if (!pagination && tableData?.length > 0) {
      const properties = Object.keys(tableData[0]);
      const cols = properties.map((key) => ({
        id: key, // Aggiungi l'ID qui
        Header: key,
        accessor: key,
        type:
          columnsVariables?.find((col) => col?.accessor === key)?.type ||
          tableData[0][key],
        filter: getFilterForType(
          columnsVariables?.find((col) => col?.accessor === key)?.type
        ),
        translationKey: columnsVariables?.find((col) => col?.accessor === key)
          ?.translationKey,
      }));
      setColumnsData(cols);

      const processedData = tableData.map((item) =>
        Object.fromEntries(
          Object.entries(item).map(([key, value]) => [key, value ?? null])
        )
      );
      setTableDataString(processedData);
    }
  }, [pagination, tableData, columnsVariables]);

  // Nascondi le colonne per la dashboard della mappa
  useEffect(() => {
    if (isMapDashboard) {
      headerGroups.forEach((headers) => {
        headers.headers.forEach((header) => {
          if (
            header.id !== "eventTime" &&
            header.id !== "action" &&
            header.id !== "description"
          )
            toggleHideColumn(header.id, true);
        });
      });
    }
  }, [toggleHideColumn, isMapDashboard, headerGroups]);

  useEffect(() => {
    const localStorageKey = `${pageType}-column-visibility`;
    if (hideColumnsOnLoad) {
      localStorage.setItem(localStorageKey, JSON.stringify(hideColumnsOnLoad));
    }
  }, [hideColumnsOnLoad]);

  useEffect(() => {
    if (refreshPage) setRefreshKey((prevKey) => (prevKey + 1) % 10);
  }, [refreshPage]);

  // Gestisci il cambiamento delle colonne nascoste
  const handleToggleColumn = (columnId) => {
    setHiddenColumns((prevHiddenColumns) => {
      if (prevHiddenColumns.includes(columnId)) {
        return prevHiddenColumns.filter((id) => id !== columnId);
      } else {
        return [...prevHiddenColumns, columnId];
      }
    });
  };

  return (
    <Box
      direction="column"
      w={isMapDashboard ? "50%" : "100%"} // || isMobileTable
      px="0px"
      overflowX={"auto"}
    >
      {!isFiltersOn && (
        <Flex>
          {!cardGrid && (!isMobileTable || !isMobile) && (
            <ColumnsButton
              allColumns={allColumns}
              toggleHideColumn={toggleHideColumn}
              pageType={pageType}
              handleToggleColumn={handleToggleColumn}
              isInDrawer={isInDrawer}
              isVisible={!areButtonsInvisible}
            />
          )}
          <FilterButton
            allColumns={allColumns}
            tableData={tableData}
            pageType={pageType}
            applyFilter={applyFilter}
            statusOptions={statusOptions}
            isInDrawer={isInDrawer}
            dependencyArray={[
              tableDataString,
              allColumns,
              hideColumnsOnLoad,
              tableData,
            ]}
            isVisible={isVisible}
            paginated={paginated}
            setFilters={setFilters}
            filters={filters}
            gotoPage={(index) => {
              return pageIndex !== 0 && paginated ? gotoPage(index) : null;
            }}
            disableColumnFilter={disableColumnFilter}
          />
          {!areButtonsInvisible && (
            <ExportButton
              columns={columns}
              rows={rows}
              allColumns={allColumns}
              pageType={pageType}
            />
          )}
          {refreshButton && (
            <Button
              onClick={() => {
                if (paginated && pagination) {
                  setRefreshKey((prevKey) => (prevKey + 1) % 10);
                  if (refreshFunction !== undefined) refreshFunction();
                } else {
                  refreshFunction();
                }
              }}
              bgColor={"pcr.200"}
              _hover={{ bgColor: "pcr.100" }}
              ml={1}
            >
              <RepeatIcon />
            </Button>
          )}
          {navigateToPage && !areButtonsInvisible && !isMobile && (
            <Button
              bgColor={"pcr.200"}
              _hover={{ bgColor: "pcr.100" }}
              ml={1}
              onClick={() => navigate(navigateToPage)}
            >
              {t(`navigate.${navigateToPage}`)}
            </Button>
          )}
        </Flex>
      )}

      {loading ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          height="200px"
          overflow={"hidden"}
        >
          <LoadingSpinner />
        </Flex>
      ) : !cardGrid && !isMobileTable ? (
        <>
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            mt="15px"
            h={"100%"}
          >
            <TableHeader
              headerGroups={headerGroups}
              isResizing={isResizing}
              setIsResizing={setIsResizing}
              isResizingRef={isResizingRef}
              columnsList={columnsList}
              pageType={pageType}
            />

            <TableContent
              onOptionChange={onOptionChange}
              selectOptions={selectOptions}
              rowClick={rowClick}
              page={page}
              prepareRow={prepareRow}
              textColor={textColor}
              columnsList={columnsList}
              pageType={pageType}
              toggleStates={toggleStates}
              onToggleChange={onToggleChange}
              statusOptions={statusOptions}
              {...getTableBodyProps()}
              customTextStyle={customTextStyle}
            />
          </Table>

          <Pagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pagination ? controlledPageCount : pageOptions.length}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        </>
      ) : !cardGrid && isMobileTable ? (
        <Box overflow={"hidden"}>
          <MobileTable
            // data={rows}
            data={page}
            pageType={pageType}
            onOptionChange={onOptionChange}
            textColor={textColor}
            selectOptions={selectOptions}
            columnsList={columnsList}
            hideColumnsOnLoad={hideColumnsOnLoad}
            prepareRow={prepareRow}
            toggleStates={toggleStates}
            onToggleChange={onToggleChange}
            {...getTableBodyProps()}
          />
          <Pagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pagination ? controlledPageCount : pageOptions.length}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageSize={pageSize}
            isMobileTable={isMobileTable}
          />
        </Box>
      ) : (
        cardGrid.component(rows)
      )}
    </Box>
  );
}
