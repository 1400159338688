import { Box, Button } from "@chakra-ui/react";
import { useNotificationStore } from "../../contexts/stores";
import React, { useEffect, useState } from "react";
import FiltersTable from "../../components/filtersTable/FiltersTable";
import { columnsData } from "./variables/columnsData";
import { NotificationsActiveOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import API from "../../api/API";
import { number } from "prop-types";

const NotificationPage = () => {
  const { t } = useTranslation();

  const statusOptions = [
    {
      key: "status",
      values: [
        { value: 0, label: t(`events_list.status_list.0`) },
        { value: 1, label: t("events_list.status_list.1") },
        { value: 2, label: t("events_list.status_list.2") },
        { value: 3, label: t("events_list.status_list.3") },
        { value: 4, label: t("events_list.status_list.4") },
      ],
    },
  ];

  const { fetchNotificationsPage } = useNotificationStore();
  const columnsList = [
    {
      Header: "notify",
      accessor: "button",
      page: "notification_list",
      customButton: (row) => {
        return row.original.status === 0 ? (
          <Button
            colorScheme="blue"
            size="sm"
            onClick={() => API.sendNotificationAgain(row.original.id)}
            leftIcon={<NotificationsActiveOutlined />}
            aria-label={t("notify_operator.send_again")}
          >
            {t("notify_operator.send_again")}
          </Button>
        ) : (<></>);
      }
    },
  ];

  const fetchPageData = async (
    pageIndex,
    itemsPerPage,
    globalFilter,
    sortBy,
    filters
  ) => {
    try {
      const result = await fetchNotificationsPage(
        pageIndex,
        itemsPerPage,
        globalFilter,
        sortBy,
        filters
      );

      return {
        data: result.data.map((d) => {
          return { ...d, status: parseInt(d.status)
        }}),
        totalItems: result.totalItems,
      };
    } catch (error) {
      console.error("Error fetching page data:", error);
      return { data: [], totalItems: 0 };
    }
  };

  const pagination = {
    fetchPage: fetchPageData,
  };

  return (
    <Box bgColor="white" padding="15px" shadow={"lg"}>
      <FiltersTable
        columnsList={columnsList}
        columnsVariables={columnsData}
        pagination={pagination}
        statusOptions={statusOptions}
        pageType={"notification_list"}
        hideColumnsOnLoad={{
          id: false,
          status: false,
          time: true,
          location: true,
          rootNames: true,
          name: true,
          level: false,
          status: true,
          title: true,
          message: false,
          webNotification: true,
          appNotification: true
        }}
        paginated={true}
        refreshButton={true}
        applyFilter={""}
        isVisible={true}
      />
    </Box>
  );
}

export default NotificationPage;