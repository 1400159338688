import React, { useState } from "react";
import { useBreakpointValue } from "@chakra-ui/react";
import SidebarStandard from "./components/SidebarStandard";
import { useMobileApp } from "contexts/stores";
import BottomAppBar from "./components/BottomAppBar";
import mobileRoutes from "mobileRoutes";

const sidebarWidth = "250px";

export default function Sidebar(props) {
  const { routes, onToggle, isOpen } = props;
  const { isMobileApp } = useMobileApp.getState();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const toggleSidebar = () => {
    onToggle(!isOpen);
  };

  return (
    <>
      {isMobile && isMobileApp ? (
        <BottomAppBar routes={mobileRoutes} />
      ) : isMobile && !isMobileApp ? (
        <BottomAppBar routes={mobileRoutes} />
      ) : (
        <SidebarStandard
          isOpen={isOpen}
          routes={routes}
          toggleSidebar={toggleSidebar}
          sidebarWidth={sidebarWidth}
        />
      )}
    </>
  );
}
