import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

export const columnsData = [
  {
    Header: "id",
    accessor: "id",
    type: "id",
  },
  {
    Header: "time",
    accessor: "time",
    type: "date",
  },
  {
    Header: "deviceRoots",
    accessor: "deviceRoots",
    type: "textTooltip",
  },
  {
    Header: "location",
    accessor: "location",
    type: "text",
  },
  {
    Header: "status",
    accessor: "status",
    type: "status",
  },
  {
    Header: "description",
    accessor: "description",
    type: "text",
  },
  {
    Header: "counter",
    accessor: "counter",
    type: "text",
  },
];
