import { Box, Text, Icon, Flex, Tooltip } from "@chakra-ui/react";
import React from "react";
import { isOperatorAvailable } from "utils/mapUser";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import { t } from "i18next";
import { truncateString } from "views/devices/components/DeviceCardGrid";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";


const maxLengthDeviceName = 10;

const cardColors = {
  [isOperatorAvailable.ONSHIFT]: "green.500",
  [isOperatorAvailable.AVAILABLE]: "orange.500",
  [isOperatorAvailable.RESTING]: "red.500",
  [isOperatorAvailable.UNAVAILABLE]: "gray",
};

const cardTextColors = {
  [isOperatorAvailable.ONSHIFT]: "black",
  [isOperatorAvailable.RESTING]: "black",
  [isOperatorAvailable.AVAILABLE]: "black",
  [isOperatorAvailable.UNAVAILABLE]: "black",
};

const OperatorCard = ({operator, index, handleOperatorSelect, handleNotificationSelect}) => {
  const getOperatorsAvailabilityStyle = (fieldStatus) => {
    switch (fieldStatus) {
      case 0:
        return {
          bgGradient: "linear(to-br, green.300, green.500)",
          boxShadow: "0 0 15px rgba(72, 187, 120, 0.6)",
        };
      case 1:
        return {
          bgGradient: "linear(to-br, red.300, red.500)",
          boxShadow: "0 0 15px rgba(245, 101, 101, 0.6)",
        };
      case 2:
        return {
          bgGradient: "linear(to-br, gray.300, gray.500)",
          boxShadow: "0 0 15px rgba(160, 160, 160, 0.6)",
        };
      default:
        return {
          bgGradient: "linear(to-br, gray.300, gray.500)",
          boxShadow: "0 0 15px rgba(160, 160, 160, 0.6)",
        };
    }
  };


  return(
    <Box
      borderRadius="xl"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="140px"
      height="140px"
//         {  ...getOperatorsAvailabilityStyle(operator.availability)}
      _hover={{
        transform: "scale(1.1)",
        transition: "transform 0.2s ease-in-out",
      }}
    >
      <Box
        key={index}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        bg={cardColors[operator.availability]}
        p={"5px"}
        textAlign="center"
        boxShadow="md"
        border="1px"
        borderColor={"blue.500"}
        width={"130px"}
        height={"130px"}
        onClick={() => {
          handleOperatorSelect(index);
        }}
        cursor="pointer"
      >
        <Text
          fontSize="lg"
          fontWeight="bold"
          color={cardTextColors[operator.availability]}
        >
          {truncateString(operator.firstName, maxLengthDeviceName)}
        </Text>
        <Text
          fontSize="lg"
          fontWeight="bold"
          color={cardTextColors[operator.availability]}
        >
          {truncateString(operator.lastName, maxLengthDeviceName)}
        </Text>
        <Text color={cardTextColors[operator.availability]}>
          {operator.location}
        </Text>
        <Text
          color={cardTextColors[operator.availability]}
          fontSize={"12px"}
        >
          {operator.number}
        </Text>

        <Flex pt={"10px"} justifyContent={"space-between"}>
          <Tooltip
            label={
              Array.isArray(operator.groups)
                ? operator.groups.join(", ")
                : operator.groups.toString()
            }
          >
            <Icon color={cardTextColors[operator.availability]}>
              <SellOutlinedIcon />
            </Icon>
          </Tooltip>
          {operator.isTicketManager && (
            <Tooltip label={t("operators_list.isTicketManager")}>
              <Icon color={cardTextColors[operator.availability]}>
                <ConfirmationNumberOutlinedIcon />
              </Icon>
            </Tooltip>
          )}
          {!operator.enabled && (
            <Tooltip label={t("operators_list.disabled")} >
              <Icon color={cardTextColors[operator.availability]}>
                <RemoveCircleOutlineOutlinedIcon />
              </Icon>
            </Tooltip>
          )}
          <Tooltip label={t("notify")}>
            <Icon
              color={cardTextColors[operator.availability]}
              onClick={(e) => {
                handleNotificationSelect(index, e);
              }}
            >
              <MessageOutlinedIcon />
            </Icon>
          </Tooltip>
        </Flex>
      </Box>
    </Box>
  )
}


export default OperatorCard;

